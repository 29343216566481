<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Allgemeine Geschäftsbedingungen</h1>
        <div class="divider"><span class="bg-red radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <p>Sehr geehrte Eltern!</p>
        <p>
          Ich m&ouml;chte mich herzlich bedanken, dass Sie meinem Team und mir das Vertrauen schenken, Ihr
          Kind
          als Kinderarzt betreuen zu d&uuml;rfen. Steigende Patientenzahlen zeigen uns einerseits, dass
          die Art
          und Weise, wie wir die Behandlung durchf&uuml;hren, gesch&auml;tzt wird. Andererseits bringen
          steigende
          Patientenzahlen naturgem&auml;&szlig; auch mit sich, dass die Vergabe und Koordination von
          Terminen
          zunehmend schwieriger wird. Die wirklichen Ordinationszeiten entsprechen schon lange nicht mehr
          denen, die am Ordinationsschild angegeben sind. Wir beginnen fr&uuml;her, h&auml;ngen meist
          mehrere
          Stunden nach Ordinationsende an und eine Mittagspause gibt es schon seit sehr langer Zeit nicht
          mehr. All das machen wir, weil wir Ihr Kind betreuen, die Wartezeit auf Termine verk&uuml;rzen
          und
          helfen wollen, dass kranke Kinder m&ouml;glichst rasch wieder gesund werden k&ouml;nnen. Und wir
          machen
          das gerne! Allerdings f&auml;llt es in den letzten Monaten zunehmend schwerer, das System
          aufrecht zu
          erhalten.
        </p>
        <p>
          Unsere Ordination wird so gef&uuml;hrt, dass f&uuml;r die Untersuchung eine Terminvereinbarung
          vorgesehen
          ist. Der Termin kann sowohl telefonisch als auch &uuml;ber die Homepage (keine Akuttermine)
          vereinbart werden. Auf diese Weise ist es uns m&ouml;glich, die Wartezeiten zu verk&uuml;rzen
          und unsere
          Ressourcen m&ouml;glichst optimal zu nutzen. Akute Ereignisse wie Atemnot oder heftige
          Bauchschmerzen, aber auch fieberhafte Erkrankungen bei S&auml;uglingen unter 6 Monaten werden
          sofort
          begutachtet. Dennoch ist es f&uuml;r uns wichtig, dass auch in diesen F&auml;llen ein Termin
          vereinbart
          wird, weil in den meisten F&auml;llen es mehrere Kinder pro Tag sind, die aufgrund dieser
          Beschwerden eingeschoben werden m&uuml;ssen. Wenn wir wissen, dass diese Patienten kommen, k&ouml;nnen
          wir
          f&uuml;r den restlichen Ordinationstag reagieren.
        </p>

        <p>
          Aber es ist naturgem&auml;&szlig; nicht m&ouml;glich, alle anderen Kinder ebenfalls vormittags
          oder sofort zu
          untersuchen. Im Regelfall wird jedes kranke Kind am Tag der Terminanfrage untersucht. Diese
          Termine werden ent-sprechend der Anmeldereihenfolge vergeben. Daher ist es durchaus m&ouml;glich,
          dass dieser Termin erst am Abend ist. Bitte beachten Sie aber, dass auch unsere Arbeitsleistung
          sowohl zeitlich als auch k&ouml;rperlich begrenzt ist. Und wir meinen, den Zeitpunkt, an dem es
          nicht
          mehr m&ouml;glich, weitere Patienten begutachten zu k&ouml;nnen, selbst bestimmen zu d&uuml;rfen.
        </p>

        <p>Wir bitten Sie daher folgende Punkte zu beachten:</p>

        <ul>
          <li>Vereinbaren Sie bitte <b class="red">f&uuml;r jede Untersuchung</b> einen Termin! Wir denken, dass es
            besser
            f&uuml;r Ihr Kind ist, zuhause im Bett als im Wartezimmer zu warten.
          </li>
          <li>
            Sollte ein <b class="red">Geschwisterkind</b> ebenfalls begutachtet werden, rufen Sie bitte ebenfalls
            an. Ihr
            Termin k&ouml;nnte ja schon ein eingeschobener sein – kleine Terminkorrekturen k&ouml;nnen
            uns evtl.
            helfen, den weiteren Tagesablauf zu steuern und bedeuten f&uuml;r Kinder mit sp&auml;teren
            Terminen
            nicht zwangsl&auml;ufig lange Wartezeiten.
          </li>
        </ul>

        <p>
          In beiden F&auml;llen finden wir, dass es jenen Patienten gegen&uuml;ber nicht fair ist, die
          sich an die
          Terminver-einbarung halten – einerseits, weil sie erst sp&auml;ter begutachtet werden k&ouml;nnen
          oder
          andererseits lange im Wartezimmer auf die Untersuchung warten m&uuml;ssen. Und irgendwann kann
          dann
          auch Ihr Kind davon betroffen sein.
        </p>

        <ul>
          <li>
            K&ouml;nnen Sie einen Termin nicht wahrnehmen, <b class="red">stornieren Sie diesen bitte
            rechtzeitig</b>. Nur so
            k&ouml;nnen wir diesen an ein anderes Kind weitergeben. Leider kommt es in letzter Zeit
            immer
            h&auml;ufiger vor, dass Termine - auch solche, die am selben Tag in der Fr&uuml;h vereinbart
            wurden -
            nicht abgesagt werden (so kommt es an manchen Tagen dazu, dass wir bis zu 50 Minuten unt&auml;tig
            sind, weil wir diese Termine naturgem&auml;&szlig; nicht vergeben k&ouml;nnen). Das f&uuml;hrt
            dazu, dass sowohl
            Akuttermine als auch Termine f&uuml;r Routineuntersuchungen (MKP, Impfungen, Blutabnahmen)
            mit
            immer l&auml;ngeren Wartezeiten verbunden sind. Nach dem offiziellen Ordinationsende l&auml;uft
            der
            Anrufbeantworter, auf den eine Nachricht gesprochen werden kann. So k&ouml;nnen Sie auch
            einen
            Termin au&szlig;erhalb der Ordinationszeiten stornieren.
          </li>
          <li>
            Bitte kommen Sie <b class="red">p&uuml;nktlich</b> zu den Untersuchungen.
          </li>
        </ul>


        <p style="text-align: center; margin-top:30px;">Wir bedanken uns im Namen aller Patienten f&uuml;r
          Ihre Mithilfe!</p>

      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'AgbView'
}
</script>

<style scoped>


</style>
