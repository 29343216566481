<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Impressum</h1>
        <div class="divider"><span class="bg-red radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-7">
        <p>Information gemäß §5 E-Commerce-Gesetz, §14 Unternehmensgesetzbuch und §63 Gewerbeordnung sowie
          Offenlegung laut §25 Mediengesetz</p>

        <h3>Für den Inhalt verantwortlich</h3>

        <p>
          Dr. Gerhard Kusolitsch<br>
          4300 St. Valentin, Hauptstraße 1<br>
          Österreich
        </p>
        <p>
          Tel.: 07435 / 52919<br>
          Fax: 07435/52919-4<br>
          Email: kinderarztkusolitsch@aon.at
        </p>


        <h3>Berufsbezeichnung</h3>
        <p>
          Mitglied der Niederösterreichischen und Österreichischen Ärztekammer<br>
          Berufsbezeichnung: Facharzt für Kinder- und Jugendheilkunde<br>
          Tätigkeit unterliegt dem Ärztegesetz 1998<br>
          (siehe /www.ris.bka.gv.at/bundesrecht)<br>
        </p>


        <h3>Haftungsausschluss</h3>
        <p>
          Wir übernehmen keinerlei Gewähr für die Aktualität, Korrektheit und Vollständigkeit der
          bereitgestellten Informationen auf dieser Website.

          Haftungsansprüche, die sich auf Schäden materieller oder ideeller Art beziehen, die durch die
          Nutzung oder Nichtnutzung der bereitgestellten Informationen verursacht wurden, sind
          grundsätzlich
          ausgeschlossen. Alle Angebote sind freibleibend und unverbindlich. Die Autoren behalten es sich
          ausdrücklich vor, Teile der Website oder das gesamte Angebot ohne gesonderte Ankündigung zu
          verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig
          einzustellen.
        </p>

        <h3>Verweise und Links</h3>
        <p>
          Wir haben keinen Einfluss auf den Inhalt anderer Homepages, auf die externe
          Links verweisen. Wir übernehmen keine Haftung hinsichtlich Richtigkeit, Aktualität oder
          eventueller
          rechtswidriger Inhalte beziehungsweise Datenschutzbestimmungen dieser anderen Homepages. Ebenso
          kann
          aufgrund der Schnelllebigkeit des Mediums das Funktionieren einzelner Links nicht garantiert
          werden.
        </p>
        <div class="space-20"></div>
      </div>

      <div class="col-12 col-md-5">
        <div class="card bg-grey shadow">
          <div class="card-body">
            <h5 class="card-title bg-red">Dokumente & Informationen <i class="bi bi-info-circle-fill"></i></h5>

            <ul class="list">
              <FileRenderer :files="dokumente" color="red" location="Impressum"/>
            </ul>
          </div>
        </div>
        <div class="space-20"></div>
      </div>


    </div>
  </div>
</template>

<script>
import FileRenderer from "@/components/FileRenderer.vue";

import {useDocumentsStore} from '@/stores/documents'


export default {
  name: 'ImpressumView',
  components: {FileRenderer},
  data: () => ({}),

  computed: {
    dokumente() {
      return useDocumentsStore().getDocuments
    }
  },
}
</script>

<style scoped>


</style>
