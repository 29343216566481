<template>
  <div class="container">
    <div class="row">
      <div class="col-12 mb-2">
        <h4>Benutzerverwaltung</h4>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <table class="table table-hover " style="width: 100%">
          <thead>
          <tr class="table-dark">
            <th scope="col" style="width: 200px;">Name</th>
            <th scope="col">E-Mail / Username</th>
            <th scope="col" style="width: 150px;">Typ</th>
            <th scope="col" style="width: 110px;"></th>
          </tr>
          </thead>
          <tbody class="">

          <tr v-for="item in users" v-bind:key="item.id">
            <td class="align-middle">{{ item.name }}</td>
            <td class="align-middle">{{ item.email }}</td>
            <td class="align-middle">{{ item.superadmin?"Admin":"User" }}</td>
            <td>
              <button @click="openEditEntryDialog(item.id)" type="button" class="btn btn-light me-2"><i
                  class="bi blue bi-pen-fill"></i></button>
              <button @click="deleteEntry(item.id)" type="button" class="btn btn-light"><i
                  class="bi blue bi-trash-fill"></i></button>
            </td>
          </tr>
          </tbody>
        </table>


        <div class="d-flex flex-row-reverse">
          <button type="button" class="btn btn-light" @click="openAddEntryDialog()"><i
              class="bi blue bi-plus-circle-fill"></i></button>

          <button type="button" class="btn btn-light me-1" @click="refresh()"><i
              class="bi blue bi-arrow-clockwise"></i></button>
        </div>

      </div>
    </div>


    <div class="modal" tabindex="-1" id="editElementModalUserManager">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Benutzereintrag bearbeiten</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-2 row">
              <label for="username" class="col-sm-3 col-form-label">Name</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" id="username" value="">
                <div class="invalid-feedback">
                  Angaben sind erforderlich
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="usermail" class="col-sm-3 col-form-label">E-Mail</label>
              <div class="col-sm-9">
                <input type="email" class="form-control" id="usermail" value="">
                <div class="invalid-feedback">
                  Keine gültige E-Mail Adresse
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="usertyp" class="col-sm-3 col-form-label">Typ</label>
              <div class="col-sm-9">
                <select class="form-select" id="usertyp">
                  <option value="User">User</option>
                  <option value="Admin">Admin</option>
                </select>
                <div class="invalid-feedback">
                  Angaben sind erforderlich
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="userpassword" class="col-sm-3 col-form-label">Passwort</label>
              <div class="col-sm-9">
                <input type="password" class="form-control" id="userpassword" value="">
                <div class="invalid-feedback">
                  Angaben sind erforderlich
                </div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <a class="button shadow medium bg-greydark" data-bs-dismiss="modal">Close</a>
            <a type="submit" class="button shadow medium bg-blue" @click="validateEditEntryDialog()">Speichern</a>
            <input type="hidden" id="userid"/>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>


<script>
import {useAdminStore} from "@/stores/admin";


export default {
  name: 'UserManager',

  computed: {
    users() {
      return useAdminStore().getUsers
    },
  },

  methods: {
    editEntry: function (id, name, email, typ, password) {
      return useAdminStore().editUser(id, name, email, typ, password);
    },

    refresh: function(){
      useAdminStore().fetchUsers();
    },

    addEntry: async function (name, email, typ, password) {
      return useAdminStore().createUser(name, email, typ, password);
    },

    deleteEntry: function (id) {
      useAdminStore().deleteUser(id);
    },

    openAddEntryDialog: function () {
      var modalDOM = document.getElementById("editElementModalUserManager");
      var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);

      modalDOM.querySelector('.modal-title').innerHTML = "Benutzereintrag erstellen";
      modalDOM.querySelector("#username").value = "";
      modalDOM.querySelector("#usermail").value = "";
      modalDOM.querySelector("#usertyp").value = "User";
      modalDOM.querySelector("#userpassword").value = "";
      modalDOM.querySelector("#userid").value = -1;

      this.clearValidationEditEntryDialog();
      modal.show();
    },

    openEditEntryDialog: function (id) {
      var eintrag = this.users.find(item => item.id === id);
      var modalDOM = document.getElementById("editElementModalUserManager");
      var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);

      modalDOM.querySelector('.modal-title').innerHTML = "Benutzereintrag bearbeiten";
      modalDOM.querySelector("#username").value = eintrag.name;
      modalDOM.querySelector("#usermail").value = eintrag.email;
      modalDOM.querySelector("#usertyp").value = eintrag.superadmin?"Admin":"User";
      modalDOM.querySelector("#userpassword").value = "";
      modalDOM.querySelector("#userid").value = eintrag.id;

      this.clearValidationEditEntryDialog();
      modal.show();
    },

    validateEditEntryDialog: function () {
      this.clearValidationEditEntryDialog();

      var modalDOM = document.getElementById("editElementModalUserManager");
      var name = modalDOM.querySelector("#username").value;
      var email = modalDOM.querySelector("#usermail").value;
      var typ = modalDOM.querySelector("#usertyp").value === "Admin";
      var password = modalDOM.querySelector("#userpassword").value;
      var id = modalDOM.querySelector("#userid").value;
      var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);


      var valid = true;


      if(name.length > 0){
        modalDOM.querySelector("#username").classList.add("is-valid");
      }else{
        modalDOM.querySelector("#username").classList.add("is-invalid");
        valid = false;
      }

      if(email.length > 0 && String(email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )) {
        modalDOM.querySelector("#usermail").classList.add("is-valid");
      }else{
        modalDOM.querySelector("#usermail").classList.add("is-invalid");
        valid = false;
      }


      if(Number(id) === -1){
        if(password.length > 0){
          modalDOM.querySelector("#userpassword").classList.add("is-valid");
        }else{
          modalDOM.querySelector("#userpassword").classList.add("is-invalid");
          valid = false;
        }
      }

      if(password.length === 0){
        password = null;
      }


      modalDOM.querySelector("#usertyp").classList.add("is-valid");


      if(valid){
        if(Number(id) !== -1){
          //EDIT MODE
          if(this.editEntry(id, name, email, typ, password)){
            modal.hide();
          }
        }else{
          //ADD MODE
          if(this.addEntry(name, email, typ, password)){
            modal.hide();
          }
        }
      }
    },

    clearValidationEditEntryDialog: function () {
      var modalDOM = document.getElementById("editElementModalUserManager");

      modalDOM.querySelector("#username").classList.remove("is-valid");
      modalDOM.querySelector("#username").classList.remove("is-invalid");

      modalDOM.querySelector("#usermail").classList.remove("is-valid");
      modalDOM.querySelector("#usermail").classList.remove("is-invalid");

      modalDOM.querySelector("#usertyp").classList.remove("is-valid");
      modalDOM.querySelector("#usertyp").classList.remove("is-invalid");

      modalDOM.querySelector("#userpassword").classList.remove("is-valid");
      modalDOM.querySelector("#userpassword").classList.remove("is-invalid");
    },
  }
}
</script>

<style scoped>

</style>