<template>
  <div class="container">
    <div class="row">
      <div class="col-12 mb-2">
        <h4>Impfstoffverwaltung</h4>
      </div>
    </div>


    <div class="row mb-5">
      <div class="col-12">
        <table class="table table-hover " style="width: 100%">
          <thead>
          <tr class="table-dark">
            <th scope="col" style="width: 250px;">Name</th>
            <th scope="col" style="width: 150px;">Impfstoff</th>
            <th scope="col" style="width: 100px;">Preis</th>
            <th scope="col" style="width: 100px;"></th>
            <th scope="col">Information</th>
            <th scope="col" style="width: 110px;"></th>
          </tr>
          </thead>
          <tbody class="">

          <tr v-for="item in impfstoffe" v-bind:key="item.id">
            <td class="align-middle">{{ item.impfung }}</td>
            <td class="align-middle">{{ item.impfstoff }}</td>
            <td class="align-middle">{{ item.preis.toFixed(2) + "€" }}</td>
            <td class="align-middle">{{ item.privat ? "Privat" : "Kasse" }}</td>
            <td class="align-middle">{{ item.info }}</td>
            <td>
              <button @click="openEditEntryDialog(item.id)" type="button" class="btn btn-light me-2"><i
                  class="bi yellow bi-pen-fill"></i></button>
              <button @click="deleteEntry(item.id)" type="button" class="btn btn-light"><i
                  class="bi yellow bi-trash-fill"></i></button>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="d-flex flex-row-reverse">
          <button type="button" class="btn btn-light" @click="openAddEntryDialog()"><i
              class="bi yellow bi-plus-circle-fill"></i></button>

          <button type="button" class="btn btn-light me-1" @click="refresh()"><i
              class="bi yellow bi-arrow-clockwise"></i></button>
        </div>

      </div>
    </div>


    <div class="modal" tabindex="-1" id="editElementModalImpfstoffManager">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Impfstoffeintrag bearbeiten</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-2 row">
              <label for="impfstoffname" class="col-sm-3 col-form-label">Name</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" id="impfstoffname" value="">
                <div class="invalid-feedback">
                  Angaben sind erforderlich
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="impfstoff" class="col-sm-3 col-form-label">Impfstoff</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" id="impfstoff" value="">
                <div class="invalid-feedback">
                  Angaben sind erforderlich
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="impfstoffpreis" class="col-sm-3 col-form-label">Preis</label>
              <div class="col-sm-9">
                <input type="number" step="0.01" class="form-control" id="impfstoffpreis" value="">
                <div class="invalid-feedback">
                  Preis kann nicht negativ sein
                </div>
              </div>
            </div>


            <div class="mb-2 row">
              <label for="impfstofftyp" class="col-sm-3 col-form-label">Typ</label>
              <div class="col-sm-9">
                <select class="form-select" id="impfstofftyp">
                  <option value="Privat">Privat</option>
                  <option value="Kasse">Kasse</option>
                </select>
                <div class="invalid-feedback">
                  Angaben sind erforderlich
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="impfstoffinformation" class="col-sm-3 col-form-label">Information</label>
              <div class="col-sm-9">
                <textarea class="form-control" id="impfstoffinformation" rows="3"></textarea>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <a class="button shadow medium bg-greydark" data-bs-dismiss="modal">Close</a>
            <a type="submit" class="button shadow medium bg-yellow" @click="validateEditEntryDialog()">Speichern</a>
            <input type="hidden" id="impfstoffid"/>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>


<script>


import {useImpfstoffeStore} from "@/stores/impfstoffe";

export default {
  name: 'ImpfstoffManager',
  data: () => ({

  }),

  computed: {
    impfstoffe: function(){
      return useImpfstoffeStore().getImpfstoffe;
    }
  },

  methods: {
    editEntry: function (id, impfung, impfstoff, preis, privat, information) {
      useImpfstoffeStore().edit(id, impfung, impfstoff, preis, privat, information);
    },

    addEntry: function (impfung, impfstoff, preis, privat, information) {
      useImpfstoffeStore().create( impfung, impfstoff, preis, privat, information);
    },

    deleteEntry: function (id) {
      useImpfstoffeStore().delete(id);
      //ACHTUNG nur möglich wenn impfstoff keiner online anmeldung zugeordnet ist
    },

    refresh: function(){
      useImpfstoffeStore().fetch();
    },

    openAddEntryDialog: function () {
      var modalDOM = document.getElementById("editElementModalImpfstoffManager");
      var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);

      modalDOM.querySelector('.modal-title').innerHTML = "Impfstoffeintrag erstellen";
      modalDOM.querySelector("#impfstoffname").value = "";
      modalDOM.querySelector("#impfstoff").value = "";
      modalDOM.querySelector("#impfstoffpreis").value = "0.00";
      modalDOM.querySelector("#impfstofftyp").value = "Kasse";
      modalDOM.querySelector("#impfstoffinformation").value = "";
      modalDOM.querySelector("#impfstoffid").value = -1;

      this.clearValidationEditEntryDialog();
      modal.show();
    },

    openEditEntryDialog: function (id) {
      var eintrag = this.impfstoffe.find(item => item.id === id);
      var modalDOM = document.getElementById("editElementModalImpfstoffManager");
      var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);

      modalDOM.querySelector('.modal-title').innerHTML = "Impfstoffeintrag erstellen";
      modalDOM.querySelector("#impfstoffname").value = eintrag.impfung;
      modalDOM.querySelector("#impfstoff").value = eintrag.impfstoff;
      modalDOM.querySelector("#impfstoffpreis").value = eintrag.preis.toFixed(2);
      modalDOM.querySelector("#impfstofftyp").value = eintrag.privat ? "Privat" : "Kasse";
      modalDOM.querySelector("#impfstoffinformation").value = eintrag.info;
      modalDOM.querySelector("#impfstoffid").value = eintrag.id;

      this.clearValidationEditEntryDialog();
      modal.show();
    },

    validateEditEntryDialog: function () {
      this.clearValidationEditEntryDialog();
      var modalDOM = document.getElementById("editElementModalImpfstoffManager");

      var name = modalDOM.querySelector("#impfstoffname").value;
      var impfstoff = modalDOM.querySelector("#impfstoff").value;
      var preis = modalDOM.querySelector("#impfstoffpreis").value;
      var typ = modalDOM.querySelector("#impfstofftyp").value;
      var information = modalDOM.querySelector("#impfstoffinformation").value;
      var id = modalDOM.querySelector("#impfstoffid").value;
      var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);

      var valid = true;


      if (name.length > 0) {
        modalDOM.querySelector("#impfstoffname").classList.add("is-valid");
      } else {
        modalDOM.querySelector("#impfstoffname").classList.add("is-invalid");
        valid = false;
      }

      if (impfstoff.length > 0) {
        modalDOM.querySelector("#impfstoff").classList.add("is-valid");
      } else {
        modalDOM.querySelector("#impfstoff").classList.add("is-invalid");
        valid = false;
      }

      modalDOM.querySelector("#impfstofftyp").classList.add("is-valid");

      if (Number(preis) >= 0) {
        modalDOM.querySelector("#impfstoffpreis").classList.add("is-valid");
      } else {
        modalDOM.querySelector("#impfstoffpreis").classList.add("is-invalid");
        valid = false;
      }

      if (valid) {
        if (Number(id) !== -1) {
          //EDIT MODE
          this.editEntry(id, name, impfstoff, preis, typ === "Privat", information);
          modal.hide();
        } else {
          //ADD MODE
          this.addEntry(name, impfstoff, preis, typ === "Privat", information);
          modal.hide();
        }
      }
    },

    clearValidationEditEntryDialog: function () {
      var modalDOM = document.getElementById("editElementModalImpfstoffManager");

      modalDOM.querySelector("#impfstoffname").classList.remove("is-valid");
      modalDOM.querySelector("#impfstoffname").classList.remove("is-invalid");

      modalDOM.querySelector("#impfstoff").classList.remove("is-valid");
      modalDOM.querySelector("#impfstoff").classList.remove("is-invalid");

      modalDOM.querySelector("#impfstoffpreis").classList.remove("is-valid");
      modalDOM.querySelector("#impfstoffpreis").classList.remove("is-invalid");

      modalDOM.querySelector("#impfstofftyp").classList.remove("is-valid");
      modalDOM.querySelector("#impfstofftyp").classList.remove("is-invalid");
    },
  }
}
</script>

<style scoped>

</style>