<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Mutterkindpass</h1>
        <div class="divider"><span class="bg-yellow radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>


    <div class="row">
      <div class="col-12 col-md-7">
        <p>Als Facharzt für Kinder- und Jugendheilkunde führe ich - mit Ausnahme der augenfachärztlichen
          Untersuchung im 22.-24. Lebensmonat - sämtliche im Rahmen des Mutter-Kind-Passes empfohlene
          Untersuchungen durch. Diese umfassen neben einem allgemeinen internen/neurologischen Status und
          diversen Fragen zum Entwicklungsstand Ihres Kindes folgende weiteren Untersuchungen:
        </p>

        <ul>
          <li>Ultraschall der Hüfte in der 1. Lebenswoche</li>
          <li>Orthopädische Untersuchung in der 4.-7. Lebenswoche</li>
          <li>Ultraschall der Hüfte in der 6.-8. Lebenswoche</li>
          <li>HNO-Untersuchung im 7.-9. Lebensmonat</li>
          <li>Augen-Untersuchung im 10.-14. Lebensmonat</li>
        </ul>


        <p>Bei Bedarf kann auch eine Sonographie der Nieren und der Harnblase („Nieren-Screening“)
          durchgeführt werden. Die Kosten dieser Untersuchung werden derzeit jedoch nur von den §2-Kassen
          (GKK, SV der Bauern, Mondi) übernommen.</p>
        <p>Bitte beachten Sie, dass sowohl die zeitgerechte Durchführung aller 5 Untersuchungen im 1.
          Lebensjahr als auch der Nachweis der Untersuchung bei Ihrer Krankenkasse Voraussetzung für den
          vollständigen Bezug des Kinderbetreuungsgeldes sind.</p>
      </div>

      <div class="col-12 col-md-5">
        <div class="card bg-grey shadow">
          <div class="card-body">
            <h5 class="card-title bg-yellow">Informationen<i class="bi bi-info-circle-fill"></i></h5>

            <ul class="list">
              <FileRenderer :files="dokumente" color="yellow" location="Mutterkindpass"/>
            </ul>
          </div>
        </div>

        <div class="space-20"></div>
      </div>

    </div>
  </div>
</template>

<script>
import FileRenderer from "@/components/FileRenderer.vue";

import { useDocumentsStore } from '@/stores/documents'

export default {
  name: 'LeistungKasseMutterkindpassView',
  components: {FileRenderer},

  computed: {
    dokumente() {
      return useDocumentsStore().getDocuments
    },
  }
}
</script>

<style scoped>


</style>
