<template>
  <div class="container">
    <div class="row">
      <div class="col-12 mb-2">
        <h4>Urlaubsverwaltung</h4>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <table class="table table-hover " style="width: 100%">
          <thead>
          <tr class="table-dark">
            <th scope="col" style="width: 150px;">Startdatum</th>
            <th scope="col" style="width: 150px;">Enddatum</th>
            <th scope="col" style="width: 150px;">Typ</th>
            <th scope="col">Text</th>
            <th scope="col" style="width: 110px;"></th>
          </tr>
          </thead>
          <tbody class="">

          <tr v-for="item in urlaub" v-bind:key="item.id">
            <td class="align-middle">{{ new Date(item.startdatum).toLocaleDateString() }}</td>
            <td class="align-middle">{{ new Date(item.enddatum).toLocaleDateString() }}</td>
            <td class="align-middle">{{ item.typ }}</td>
            <td class="align-middle">{{ item.text }}</td>
            <td>
              <button @click="openEditEntryDialog(item.id)" type="button" class="btn btn-light me-2"><i
                  class="bi yellow bi-pen-fill"></i></button>
              <button @click="deleteEntry(item.id)" type="button" class="btn btn-light"><i
                  class="bi yellow bi-trash-fill"></i></button>
            </td>
          </tr>
          </tbody>
        </table>


        <div class="d-flex flex-row-reverse">
          <button type="button" class="btn btn-light" @click="openAddEntryDialog()"><i
              class="bi yellow bi-plus-circle-fill"></i></button>

          <button type="button" class="btn btn-light me-1" @click="refresh()"><i
              class="bi yellow bi-arrow-clockwise"></i></button>
        </div>

      </div>
    </div>


    <div class="modal" tabindex="-1" id="editElementModalUrlaubManager">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Urlaubseintrag bearbeiten</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-2 row">
              <label for="urlaubstartdatum" class="col-sm-3 col-form-label">Startdatum</label>
              <div class="col-sm-9">
                <input type="date" class="form-control" id="urlaubstartdatum" value="">
                <div class="invalid-feedback">
                  Ungültiges Datum
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="urlaubenddatum" class="col-sm-3 col-form-label">Enddatum</label>
              <div class="col-sm-9">
                <input type="date" class="form-control" id="urlaubenddatum" value="">
                <div class="invalid-feedback">
                  Das Datum muss nach dem Startdatum sein.
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="urlaubtyp" class="col-sm-3 col-form-label">Typ</label>
              <div class="col-sm-9">
                <select class="form-select" id="urlaubtyp">
                  <option value="Urlaub">Urlaub</option>
                  <option value="Ankündigung">Ankündigung</option>
                </select>
                <div class="invalid-feedback">
                  Angaben sind erforderlich
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="urlaubtext" class="col-sm-3 col-form-label">Text</label>
              <div class="col-sm-9">
                <textarea class="form-control" id="urlaubtext" rows="3"></textarea>
                <div class="invalid-feedback">
                  Angaben sind erforderlich
                </div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <a class="button shadow medium bg-greydark" data-bs-dismiss="modal">Close</a>
            <a type="submit" class="button shadow medium bg-yellow" @click="validateEditEntryDialog()">Speichern</a>
            <input type="hidden" id="urlaubid"/>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>


<script>

import {useTermineStore} from "@/stores/termine";


export default {
  name: 'UrlaubManager',

  computed: {
    urlaub() {
      return useTermineStore().getTermine
    },
  },

  methods: {
    editEntry: function (id, startdatum, enddatum, typ, text) {
      useTermineStore().edit(id, startdatum, enddatum, typ, text);
    },

    refresh: function(){
      useTermineStore().fetch();
    },

    addEntry: async function (startdatum, enddatum, typ, text) {
      useTermineStore().create(startdatum, enddatum, typ, text);
    },

    deleteEntry: function (id) {
      useTermineStore().delete(id);
    },

    openAddEntryDialog: function () {
      var modalDOM = document.getElementById("editElementModalUrlaubManager");
      var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);

      modalDOM.querySelector('.modal-title').innerHTML = "Urlaubseintrag erstellen";
      modalDOM.querySelector("#urlaubstartdatum").value = "";
      modalDOM.querySelector("#urlaubenddatum").value = "";
      modalDOM.querySelector("#urlaubtext").value = "";
      modalDOM.querySelector("#urlaubtyp").value = "Urlaub";
      modalDOM.querySelector("#urlaubid").value = -1;

      this.clearValidationEditEntryDialog();
      modal.show();
    },

    openEditEntryDialog: function (id) {
      var eintrag = this.urlaub.find(item => item.id === id);
      var modalDOM = document.getElementById("editElementModalUrlaubManager");
      var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);

      modalDOM.querySelector('.modal-title').innerHTML = "Urlaubseintrag bearbeiten";
      modalDOM.querySelector("#urlaubstartdatum").value = eintrag.startdatum;
      modalDOM.querySelector("#urlaubenddatum").value = eintrag.enddatum;
      modalDOM.querySelector("#urlaubtext").value = eintrag.text;
      modalDOM.querySelector("#urlaubtyp").value = eintrag.typ;
      modalDOM.querySelector("#urlaubid").value = eintrag.id;


      this.clearValidationEditEntryDialog();
      modal.show();
    },

    validateEditEntryDialog: function () {
      this.clearValidationEditEntryDialog();

      var modalDOM = document.getElementById("editElementModalUrlaubManager");
      var startdatum = modalDOM.querySelector("#urlaubstartdatum").value;
      var enddatum = modalDOM.querySelector("#urlaubenddatum").value;
      var text = modalDOM.querySelector("#urlaubtext").value;
      var id = modalDOM.querySelector("#urlaubid").value;
      var typ = modalDOM.querySelector("#urlaubtyp").value;
      var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);


      var valid = true;


      if(!isNaN(new Date(startdatum))){
        modalDOM.querySelector("#urlaubstartdatum").classList.add("is-valid");
      }else{
        modalDOM.querySelector("#urlaubstartdatum").classList.add("is-invalid");
        valid = false;
      }

      if(!isNaN(new Date(enddatum)) && (new Date(enddatum) >= new Date(startdatum))) {
        modalDOM.querySelector("#urlaubenddatum").classList.add("is-valid");
      }else{
        modalDOM.querySelector("#urlaubenddatum").classList.add("is-invalid");
        valid = false;
      }

      modalDOM.querySelector("#urlaubtyp").classList.add("is-valid");

      if(text.length > 0){
        modalDOM.querySelector("#urlaubtext").classList.add("is-valid");
      }else{
        modalDOM.querySelector("#urlaubtext").classList.add("is-invalid");
        valid = false;
      }

      if(valid){
        if(Number(id) !== -1){
          //EDIT MODE
          this.editEntry(id, startdatum, enddatum, typ, text);
          modal.hide();
        }else{
          //ADD MODE
          this.addEntry(startdatum, enddatum, typ, text);
          modal.hide();
        }
      }
    },

    clearValidationEditEntryDialog: function () {
      var modalDOM = document.getElementById("editElementModalUrlaubManager");

      modalDOM.querySelector("#urlaubstartdatum").classList.remove("is-valid");
      modalDOM.querySelector("#urlaubstartdatum").classList.remove("is-invalid");

      modalDOM.querySelector("#urlaubenddatum").classList.remove("is-valid");
      modalDOM.querySelector("#urlaubenddatum").classList.remove("is-invalid");

      modalDOM.querySelector("#urlaubtext").classList.remove("is-valid");
      modalDOM.querySelector("#urlaubtext").classList.remove("is-invalid");

      modalDOM.querySelector("#urlaubtyp").classList.remove("is-valid");
      modalDOM.querySelector("#urlaubtyp").classList.remove("is-invalid");
    },
  }
}
</script>

<style scoped>

</style>