<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Kontakt</h1>
        <div class="divider"><span class="bg-blue radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>


    <div class="row">
      <div class="col-12 col-md-5">
        <div class="card bg-grey shadow">
          <div class="card-body">
            <h5 class="card-title bg-blue">Adresse <i class="bi bi-geo-alt-fill"></i></h5>
            <span>Hauptstraße 1<br>4300 St. Valentin<br>Niederösterreich</span>
          </div>
        </div>

        <div class="space-20"></div>

        <div class="card bg-grey shadow">
          <div class="card-body">
            <h5 class="card-title bg-blue">Telefon <i class="bi bi-telephone-fill"></i></h5>
            <span>Tel.: <a class="blue" href="tel:+437435/52919">07435/52919</a><br>Fax.: <a class="blue" href="tel:+437435/52919-4">07435/52919-4</a></span>
          </div>
        </div>

        <div class="space-20"></div>

        <div class="card bg-grey shadow">
          <div class="card-body">
            <h5 class="card-title bg-blue">E-Mail <i class="bi bi-envelope-fill"></i></h5>
            <span>E-Mail: <a class="blue" href="mailto:kinderarztkusolitsch@aon.at">kinderarztkusolitsch@aon.at</a></span>
          </div>
        </div>

        <div class="space-20"></div>
      </div>

      <div class="col-12 col-md-7">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2660.64584876738!2d14.531739399999985!3d48.174906499999985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4773b7177ac4a3c3%3A0x21a359601c5ef731!2sHauptstra%C3%9Fe+1%2C+4300+St.+Valentin!5e0!3m2!1sde!2sat!4v1439398239812"
                width="100%" style="border:0; height: calc(100% - 20px); min-height: 300px; margin-bottom: 10px" allowfullscreen></iframe>
      </div>
    </div>
  </div></template>

<script>
export default {
  name: 'KontaktView'
}
</script>

<style scoped>


</style>
