<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Adminbereich</h1>
        <div class="divider"><span class="bg-red radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>


    <div class="row">
      <div class="col-6">

        <div class="mb-3">
          <label for="username" class="form-label">Username</label>
          <input type="text" class="form-control" name="username" id="username" value="">
          <div class="invalid-feedback">Ungültiger Username</div>
        </div>

        <label for="password" class="form-label">Passwort</label>

        <div class="input-group mb-3">
          <input type="password" class="form-control" id="password" name="password" value="">
          <button class="btn btn-outline-secondary" type="button" @click="togglePasswordVisibility"><i class="bi bi-eye-fill" id="passwordVisibilityButton"></i></button>
        </div>


        <a class="button bg-red  medium shadow me-3" @click="login">Login</a>
      </div>
    </div>
  </div>
</template>

<script>
import {useAdminStore} from "@/stores/admin";

export default {
  name: 'AdminLoginView',
  data: () => ({
    passwordVisible: false
  }),
  computed: {
    loggedIn() {
      return useAdminStore().isLoggedIn
    },
  },
  mounted() {
    if (useAdminStore().isLoggedIn) {
      this.$router.push("/admin/");
    }
  },
  watch: {
    loggedIn: function (newVal) { // watch it
      if (newVal) {
        this.$router.push("/admin/");
      }
    }
  },
  methods: {
    login: async function () {
      const username = document.getElementById("username").value;
      const password = document.getElementById("password").value;

      const result = await useAdminStore().login(username, password);
      if (!result) {
        //TODO: enable hint wrong credentials
        document.getElementById("username").classList.add("is-invalid");
        document.getElementById("password").classList.add("is-invalid");
      }else{
        document.getElementById("username").classList.remove("is-invalid");
        document.getElementById("password").classList.remove("is-invalid");
      }
    },

    togglePasswordVisibility: function () {
      this.passwordVisible = !this.passwordVisible;

      if (this.passwordVisible) {
        document.getElementById("password").type = "text";
        document.getElementById("passwordVisibilityButton").classList.add("bi-eye-slash-fill");
        document.getElementById("passwordVisibilityButton").classList.remove("bi-eye-fill");
      } else {
        document.getElementById("password").type = "password";
        document.getElementById("passwordVisibilityButton").classList.remove("bi-eye-slash-fill");
        document.getElementById("passwordVisibilityButton").classList.add("bi-eye-fill");
      }
    },
  }
}
</script>

<style scoped>


</style>
