<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Reanimation im Kindesalter</h1>
        <div class="divider"><span class="bg-yellow radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>


    <div class="row">
      <div class="col-12 col-md-7">
        <p>Die Versorgung eines Kindes mit einem Atem- oder Kreislaufstillstand stellt eine besondere
          Herausforderung dar. Daher sind das rasche Erkennen dieses lebensbedrohlichen Zustandes sowie
          ein rasches Handeln die Grundvoraussetzungen für ein erfolgreiches Management.<br><br>
          Kindernotfälle sind ein seltenes Ereignis und daher mit einem hohen emotionalen Druck verbunden.
          Folglich kommt es häufig zu unkoordinierten Handlungsabläufen bei der Wiederbelebung.<br><br>
          In diesem Kinderreanimationstraining sollen Ihnen die Richtlinien der Wiederbelebung im
          Säuglings- und Kindesalter gemäß des European Resuscitation Councils (ERC) vermittelt
          werden.<br><br>
          Nach einer kurzen, theoretischen Einführung in die Kinderreanimation erlernen Sie anhand
          verschiedener Szenarien sowohl an einer Säuglings- als auch an der Kleinkinderpuppe den
          strukturierten Ablauf einer Wiederbelebung.<br>
        </p>

      </div>

      <div class="col-12 col-md-5">

        <div class="card bg-grey shadow">
          <div class="card-body">
            <h5 class="card-title bg-yellow">Unterlagen<i class="bi bi-heart-pulse-fill"></i></h5>

            <ul class="list">
              <FileRenderer :files="dokumente" color="yellow" location="Reanimationstraining"/>

            </ul>


          </div>
        </div>

        <div class="space-20"></div>

      </div>
    </div>
  </div>
</template>

<script>
import FileRenderer from "@/components/FileRenderer.vue";

import { useDocumentsStore } from '@/stores/documents'


export default {
  name: 'LeistungReanimationstrainingView',
  components: {FileRenderer},

  computed: {
    dokumente() {
      return useDocumentsStore().getDocuments
    }
  },
}
</script>

<style scoped>


</style>
