// stores/counter.js
import { defineStore } from 'pinia'
import api from "@/axios";

export const useOrdinationszeitenStore = defineStore('ordinationszeiten', {
    state: () => ({
        zeiten: []
    }),
    getters:{
        getZeitenOrdination(state){
            var zeiten = [];

            state.zeiten.forEach(function (item){
                var el = zeiten.find(element => element.tag === item.tag);
                if(el !== undefined){
                    el.zeiten.push(item.zeit);
                }else{
                    zeiten.push({tag: item.tag, zeiten: [item.zeit]})
                }
            });
            return zeiten;
        },

        getZeiten(state){
            return state.zeiten;
        }
    },

    actions: {
        async fetch() {
            try {
                const response = await api.get("/ordinationszeiten");
                if(response.status === 200){
                    this.zeiten = response.data;
                }
            } catch (error) {
                error;
            }
        },

        async delete(id){
            try {
                var res = await api.delete("/ordinationszeiten/" + id, {});
                if(res.status === 204){
                    await this.fetch();
                    return true;
                }
            }catch (e) {
                e;
            }
            return false;
        },

        async create(tag, zeit){
            try {
                var res = await api.put("/ordinationszeiten", {
                    tag : tag,
                    zeit: zeit
                });

                if(res.status === 204){
                    await this.fetch();
                    return true;
                }
            }catch (e) {
                e;
            }
            return false;
        },

        async edit(id, tag, zeit){
            try {
                var res = await api.post("/ordinationszeiten/" + id, {
                    tag : tag,
                    zeit: zeit
                });

                if(res.status === 204){
                    await this.fetch();
                    return true;
                }
            }catch (e) {
                e;
            }
            return false;
        }
    },
})