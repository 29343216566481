<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Es tut uns leid</h1>
        <div class="divider"><span class="bg-red radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <p>Die von Ihnen gesuchte Seite konnte nicht gefunden werden.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorNotFoundView'
}
</script>

<style scoped>


</style>
