// stores/counter.js
import {defineStore} from 'pinia'
import api from "@/axios";


export const useAdminStore = defineStore('admin', {
    state: () => ({
        loggedIn: false,
        superAdmin: false,

        users: [],
        termine:[],
        befunde: [],
    }),

    persist: true,

    getters: {
        isLoggedIn(store) {
            return store.loggedIn;
        },
        isSuperAdmin(store) {
            return store.superAdmin;
        },

        getUsers(store){
            return store.users;
        },

        getTermine(store){
            return [
                ...store.termine.filter(item => item.status === "Anfrage"),
                ...store.termine.filter(item => item.status === "Angenommen"),
                ...store.termine.filter(item => item.status === "Abgelehnt"),
                ...store.termine.filter(item => item.status === "Bearbeitet"),
            ];
        },

        getBefunde(store){
            return store.befunde;
        },
    },
    actions: {
        fetch(){
            if(this.loggedIn){
                if(this.superAdmin){
                    this.fetchUsers();
                }

                this.fetchTermine();
                this.fetchBefunde();

                //todo: termine und befunde
            }
        },

        async fetchUsers(){
            try {
                const response = await api.get("/users");
                if(response.status === 200){
                    this.users = response.data;
                    return true;
                }
            } catch (error) {
                error;
            }

            return false;
        },

        async deleteUser(id){
            try {
                var res = await api.delete("/users/" + id, {});
                if(res.status === 204){
                    return this.fetchUsers();
                }
            }catch (e) {
                e;
            }
            return false;
        },

        async createUser(name, email, typ, password){
            try {
                var res = await api.put("/users", {
                    name : name,
                    email: email,
                    superadmin: typ,
                    password: password
                });

                if(res.status === 204){
                    return this.fetchUsers();
                }
            }catch (e) {
                e;
            }
            return false;
        },

        async editUser(id, name, email, typ, password){
            try {
                var res = await api.post("/users/" + id, {
                    name : name,
                    email: email,
                    superadmin: typ,
                    password: password
                });

                if(res.status === 204){
                    return this.fetchUsers();
                }
            }catch (e) {
                e;
            }
            return false;
        },


        async fetchTermine(){
            try {
                const response = await api.get("/terminanfragen");
                if(response.status === 200){
                    this.termine = response.data;
                    return true;
                }
            } catch (error) {
                error;
            }

            return false;
        },

        async deleteTermin(id){
            try {
                var res = await api.delete("/terminanfragen/" + id, {});
                if(res.status === 204){
                    return this.fetchTermine();
                }
            }catch (e) {
                e;
            }
            return false;
        },

        async editTermin(id, terminDateTime, terminAnmerkung){
            try {
                var res = await api.post("/terminanfragen/" + id, {
                    termin : terminDateTime,
                    anmerkung: terminAnmerkung,
                });

                if(res.status === 204){
                    return this.fetchTermine();
                }
            }catch (e) {
                e;
            }
            return false;
        },

        async fetchBefunde(){
            try {
                const response = await api.get("/befunde");
                if(response.status === 200){
                    this.befunde = response.data;
                    return true;
                }
            } catch (error) {
                error;
            }

            return false;
        },

        async deleteBefund(id){
            try {
                var res = await api.delete("/befunde/" + id, {});
                if(res.status === 204){
                    return this.fetchBefunde();
                }
            }catch (e) {
                e;
            }
            return false;
        },

        async createBefund(vorname, nachname, geburtsdatum, svnr, email, file){
            try {
                var data = new FormData();
                data.append('vorname', vorname);
                data.append('nachname', nachname);
                data.append('geburtsdatum', geburtsdatum);
                data.append('svnr', svnr);
                data.append('email', email);
                data.append('file', file);
                data.append('_method', 'put');

                var res = await api.post("/befunde", data, {
                    headers: {'Content-Type': 'multipart/form-data'}
                });
                if(res.status === 204){
                    await this.fetchBefunde();
                    return true;
                }
            }catch (e) {
                e;
            }
            return false;
        },

        async login(username, password) {
            if (this.loggedIn === true)
                return true;

            try {
                await api.get("/csrf-cookie",);
                const result = await api.post('/auth/login', {
                    email: username,
                    password: password,
                })

                if (result.status === 204) {
                    await this.fetchUserType();
                    this.loggedIn = true;
                    return true;
                } else {
                    if (await this.checkLogin()) {
                        await this.fetchUserType();
                        this.loggedIn = true;
                        return true;
                    }
                }
            } catch (e) {
                if (e["reponse"] !== null && e.response.status !== 422) {
                    if (await this.checkLogin()) {
                        await this.fetchUserType();
                        this.loggedIn = true;
                        return true;
                    }
                }
            }

            this.loggedIn = false;
            return false;
        },

        async checkLogin() {
            try {
                await api.get('/auth/checklogin', {});
                return true;
            } catch (e) {
                e;
                return false;
            }
        },

        async fetchUserType() {
            try {
                var result = await api.get('/auth/user', {});
                if (result.status === 200) {
                    this.superAdmin = result.data["superadmin"];
                } else {
                    this.superAdmin = false;
                }
            } catch (error) {
                this.superAdmin = false;
                error;
            }
        },

        async logout() {
            try {
                await api.post('/auth/logout', {});
            } catch (e) {
                e;

            } finally {
                this.loggedIn = false;
            }
        }
    },
})