// stores/counter.js
import { defineStore } from 'pinia'
import api from "@/axios";


export const useTermineStore = defineStore('termine', {
    state: () => ({
        termine: [],
    }),
    persist: false,
    getters:{
      getTermine(store){
          return store.termine;
      }
    },
    actions: {
        async fetch() {
            try {
                const response = await api.get("/termine");
                if(response.status === 200){
                    this.termine = response.data;
                }
            } catch (error) {
                error;
            }
        },

        async delete(id){
            try {
                var res = await api.delete("/termine/" + id, {});
                if(res.status === 204){
                    await this.fetch();
                    return true;
                }
            }catch (e) {
                e;
            }
            return false;
        },

        async create(startdatum, enddatum, typ, text){
            try {
                var res = await api.put("/termine", {
                    startdatum : startdatum,
                    enddatum: enddatum,
                    typ: typ,
                    text: text
                });

                if(res.status === 204){
                    await this.fetch();
                    return true;
                }
            }catch (e) {
                e;
            }
            return false;
        },

        async edit(id, startdatum, enddatum, typ, text){
            try {
                var res = await api.post("/termine/" + id, {
                    startdatum : startdatum,
                    enddatum: enddatum,
                    typ: typ,
                    text: text
                });

                if(res.status === 204){
                    await this.fetch();
                    return true;
                }
            }catch (e) {
                e;
            }
            return false;
        }
    },
})