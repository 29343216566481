// stores/counter.js
import {defineStore} from 'pinia'
import api from "@/axios";

export const useImpfstoffeStore = defineStore('impfstoffe', {
    state: () => ({
        impfstoffe: [],

    }),
    getters: {
        getImpfstoffe(store) {
            return store.impfstoffe.sort((a, b) => a.impfung.localeCompare(b.impfung));
        },

        getImpfstoffeKasse(store) {
            return store.impfstoffe.filter(item => Boolean(item.privat) === false);
        },

        getImpfstoffePrivat(store) {
            return store.impfstoffe.filter(item => Boolean(item.privat) === true);
        },
    },
    actions: {
        async fetch() {
            try {
                const response = await api.get("/impfungen");
                if(response.status === 200){
                    this.impfstoffe = response.data;
                }
            } catch (error) {
                error;
            }
        },

        async delete(id){
            try {
                var res = await api.delete("/impfungen/" + id, {});
                if(res.status === 204){
                    await this.fetch();
                    return true;
                }
            }catch (e) {
                e;
            }
            return false;
        },

        async create(impfung, impfstoff, preis, privat, information){
            try {
                var res = await api.put("/impfungen", {
                    impfung : impfung,
                    impfstoff: impfstoff,
                    preis: preis,
                    privat: privat,
                    info: information
                });

                if(res.status === 204){
                    await this.fetch();
                    return true;
                }
            }catch (e) {
                e;
            }
            return false;
        },

        async edit(id, impfung, impfstoff, preis, privat, information){
            try {
                var res = await api.post("/impfungen/" + id, {
                    impfung : impfung,
                    impfstoff: impfstoff,
                    preis: preis,
                    privat: privat,
                    info: information
                });

                if(res.status === 204){
                    await this.fetch();
                    return true;
                }
            }catch (e) {
                e;
            }
            return false;
        }
    },
})