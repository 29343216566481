// stores/counter.js
import { defineStore } from 'pinia'
import api from "@/axios";

export const useDocumentsStore = defineStore('documents', {
    state: () => ({
        documents: []
    }),
    getters:{
        getDocuments(state){
            return state.documents.sort((a, b) => a.location.localeCompare(b.location));
        }
    },

    actions: {
        async fetch() {
            try {
                const response = await api.get("/informationfiles");
                if (response.status === 200) {
                    this.documents = response.data;
                }
            } catch (error) {
                error;
            }
        },

        async delete(id){
            try {
                var res = await api.delete("/informationfiles/" + id, {});
                if(res.status === 204){
                    await this.fetch();
                    return true;
                }
            }catch (e) {
                e;
            }
            return false;
        },

        async create(bereich, bezeichnung, titel, beschreibung, file){
            try {
                var data = new FormData();
                data.append('location', bereich);
                data.append('name', bezeichnung);
                data.append('title', titel);
                data.append('description', beschreibung);
                data.append('file', file);
                data.append('_method', 'put');

                var res = await api.post("/informationfiles", data, {
                    headers: {'Content-Type': 'multipart/form-data'}
                });
                if(res.status === 204){
                    await this.fetch();
                    return true;
                }
            }catch (e) {
                e;
            }
            return false;
        },



        async edit(id, bereich, bezeichnung, titel, beschreibung, file){
            try {
                var data = new FormData();
                data.append('location', bereich);
                data.append('name', bezeichnung);
                data.append('title', titel);
                data.append('description', beschreibung);
                data.append('_method', 'post');

                if(file !== null)
                    data.append('file', file);

                var res = await api.post("/informationfiles/" + id, data, {
                    headers: {'Content-Type': 'multipart/form-data'}
                });
                if(res.status === 204){
                    await this.fetch();
                    return true;
                }
            }catch (e) {
                e;
            }
            return false;
        }
    },
})