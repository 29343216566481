

<template>
    <li v-for="(file, index) in filteredFiles" v-bind:key="file.id">
      <div>
        <div style="width:60px; float:left; transform: rotate(0);" class="center">
          <div class="bg-dark radius-top padding-8 card-button-top">
            <h2 class="white margin-0"><i class="bi bi-file-earmark-arrow-down-fill"></i></h2>
          </div>
          <div class="radius-bottom padding-8 shadow card-button" :class="buttonColor">
            <a class="stretched-link" :href="file.file" target="_blank"><h6 class="white margin-0">PDF</h6></a>
          </div>
        </div>

        <div class="marginleft-80" style="min-height:90px;">
          <h4 class="dark">{{file.title}}</h4>
          <div class="space-5"></div>
          <span>{{file.description}}</span>
        </div>
      </div>
      <div v-if="filteredFiles.length - 1 > index" class="clear space-16"></div>
    </li>

    <li v-if="(filteredFiles.length === 0) && !hideEmptyInformation">
      <div>
        <div style="width:60px; float:left; transform: rotate(0);" class="center">
          <div class="bg-dark radius-top padding-8 card-button-top">
            <h2 class="white margin-0"><i class="bi bi-archive-fill"></i></h2>
          </div>
          <div class="radius-bottom padding-8 shadow" :class="buttonColor">
            <h6 class="white margin-0">ups ...</h6>
          </div>
        </div>

        <div class="marginleft-80" style="min-height:90px;">
          <h4 class="dark">Keine neuen Dokumente</h4>
          <div class="space-5"></div>
          <span>Aktuell sind keine Dokumente vorhanden</span>
        </div>
      </div>
    </li>
</template>

<script>

export default {
  name: 'FileRenderer',

  computed:{
    buttonColor: function (){
      return "bg-" + this.color;
    },

    filteredFiles: function(){
      return this.files.filter(item => item.location === this.location);
    }
  },

  props: {
    files: {
      type: Array,
    },
    color: {
      type: String,
      default: 'red',
    },
    location: {
      type: String,
    },
    hideEmptyInformation: {
      type: Boolean,
      default: false,
    }
  }
}

</script>

<style scoped>

</style>