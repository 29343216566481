<template>
  <div class="container">
    <div class="row">
      <div class="col-12 mb-2">
        <h4>Dateiverwaltung</h4>
      </div>
    </div>


    <div class="row mb-5">
      <div class="col-12">
        <table class="table table-hover " style="width: 100%">
          <thead>
          <tr class="table-dark">
            <th scope="col" style="width: 150px;">Bereich</th>
            <th scope="col" style="width: 150px;">Bezeichnung</th>
            <th scope="col" style="width: 150px;">Titel</th>
            <th scope="col">Beschreibung</th>
            <th scope="col" style="width: 110px;"></th>
          </tr>
          </thead>
          <tbody class="">

          <tr v-for="item in dokumente" v-bind:key="item.id">
            <td class="align-middle">{{ item.location }}</td>
            <td class="align-middle">{{ item.name }}</td>
            <td class="align-middle">{{ item.title }}</td>
            <td class="align-middle">{{ item.description }}</td>
            <td>
              <button @click="openEditEntryDialog(item.id)" type="button" class="btn btn-light me-2"><i
                  class="bi yellow bi-pen-fill"></i></button>
              <button @click="deleteEntry(item.id)" type="button" class="btn btn-light"><i
                  class="bi yellow bi-trash-fill"></i></button>
            </td>
          </tr>
          </tbody>
        </table>




        <div class="d-flex flex-row-reverse">

          <button type="button" class="btn btn-light" @click="openAddEntryDialog()"><i
              class="bi yellow bi-plus-circle-fill"></i></button>

          <button type="button" class="btn btn-light me-1" @click="refresh()"><i
              class="bi yellow bi-arrow-clockwise"></i></button>
        </div>


      </div>
    </div>


    <div class="modal" tabindex="-1" id="editElementModalFileManager">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Urlaubseintrag bearbeiten</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-2 row">
              <label for="filebereich" class="col-sm-3 col-form-label">Bereich</label>
              <div class="col-sm-9">
                <select class="form-select" id="filebereich">
                  <option value="Asthma-Jugendliche">Asthma-Jugendliche</option>
                  <option value="Asthma-Kleinkind">Asthma-Kleinkind</option>
                  <option value="Home">Home</option>
                  <option value="Impfberatung">Impfberatung</option>
                  <option value="Impfen-Kasse">Impfen-Kasse</option>
                  <option value="Impfen-Privat">Impfen-Privat</option>
                  <option value="Impressum">Impressum</option>
                  <option value="Mutterkindpass">Mutterkindpass</option>
                  <option value="Reanimationstraining">Reanimationstraining</option>
                </select>

                <div class="invalid-feedback">
                  Angaben sind erforderlich
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="filebezeichnung" class="col-sm-3 col-form-label">Bezeichnung</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" id="filebezeichnung" value="">
                <div class="invalid-feedback">
                  Angaben sind erforderlich
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="filetitel" class="col-sm-3 col-form-label">Titel</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" id="filetitel" value="">
                <div class="invalid-feedback">
                  Angaben sind erforderlich
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="filebeschreibung" class="col-sm-3 col-form-label">Text</label>
              <div class="col-sm-9">
                <textarea class="form-control" id="filebeschreibung" rows="3"></textarea>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="file" class="col-sm-3 col-form-label">Datei</label>
              <div class="col-sm-9">
                <div class="input-group">
                  <a class="btn btn-outline-secondary d-none" href="#" id="datei_alt" target="_blank"><i class="bi bi-eye-fill"></i></a>
                  <input type="file" class="form-control" id="file" value="">
                  <div class="invalid-feedback">
                    Datei konnte nicht hochgeladen werden
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div class="modal-footer">
            <a class="button shadow medium bg-greydark" data-bs-dismiss="modal">Close</a>
            <a type="submit" class="button shadow medium bg-yellow" @click="validateEditEntryDialog()">Speichern</a>
            <input type="hidden" id="fileid"/>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>


<script>


import {useDocumentsStore} from "@/stores/documents";

export default {
  name: 'FileManager',
  computed: {
    dokumente(){
      return useDocumentsStore().getDocuments;
    }
  },

  methods: {
    editEntry: function (id, bereich, bezeichnung, titel, beschreibung, file) {
      return useDocumentsStore().edit(id, bereich, bezeichnung, titel, beschreibung, file);
    },

    refresh: function(){
      useDocumentsStore().fetch();
    },

    addEntry: function (bereich, bezeichnung, titel, beschreibung, file) {
      return useDocumentsStore().create(bereich, bezeichnung, titel, beschreibung, file);
    },

    deleteEntry: function (id) {
      useDocumentsStore().delete(id);
    },

    openAddEntryDialog: function () {
      var modalDOM = document.getElementById("editElementModalFileManager");
      var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);

      modalDOM.querySelector('.modal-title').innerHTML = "Dateieintrag erstellen";
      modalDOM.querySelector("#filebereich").value = "Home";
      modalDOM.querySelector("#filebezeichnung").value = "";
      modalDOM.querySelector("#filetitel").value = "";
      modalDOM.querySelector("#filebeschreibung").value = "";
      modalDOM.querySelector("#fileid").value = -1;
      modalDOM.querySelector("#file").value = "";
      modalDOM.querySelector("#datei_alt").classList.remove("d-none");
      modalDOM.querySelector("#datei_alt").classList.add("d-none");


      this.clearValidationEditEntryDialog();
      modal.show();
    },

    openEditEntryDialog: function (id) {
      var eintrag = this.dokumente.find(item => item.id === id);
      var modalDOM = document.getElementById("editElementModalFileManager");
      var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);

      modalDOM.querySelector('.modal-title').innerHTML = "Dateieintrag bearbeiten";
      modalDOM.querySelector("#filebereich").value = eintrag.location;
      modalDOM.querySelector("#filebezeichnung").value = eintrag.name;
      modalDOM.querySelector("#filetitel").value = eintrag.title;
      modalDOM.querySelector("#filebeschreibung").value = eintrag.description;
      modalDOM.querySelector("#fileid").value = eintrag.id;
      modalDOM.querySelector("#file").value = "";
      modalDOM.querySelector("#datei_alt").classList.remove("d-none");
      modalDOM.querySelector("#datei_alt").href = eintrag.file;

      this.clearValidationEditEntryDialog();
      modal.show();
    },

    validateEditEntryDialog: async function () {
      this.clearValidationEditEntryDialog();

      var modalDOM = document.getElementById("editElementModalFileManager");
      var bereich = modalDOM.querySelector("#filebereich").value;
      var bezeichnung = modalDOM.querySelector("#filebezeichnung").value;
      var titel = modalDOM.querySelector("#filetitel").value;
      var id = modalDOM.querySelector("#fileid").value;
      var beschreibung = modalDOM.querySelector("#filebeschreibung").value;




      var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);


      var valid = true;


      if (bereich.length > 0) {
        modalDOM.querySelector("#filebereich").classList.add("is-valid");
      } else {
        modalDOM.querySelector("#filebereich").classList.add("is-invalid");
        valid = false;
      }

      if (bezeichnung.length > 0) {
        modalDOM.querySelector("#filebezeichnung").classList.add("is-valid");
      } else {
        modalDOM.querySelector("#filebezeichnung").classList.add("is-invalid");
        valid = false;
      }

      if (titel.length > 0) {
        modalDOM.querySelector("#filetitel").classList.add("is-valid");
      } else {
        modalDOM.querySelector("#filetitel").classList.add("is-invalid");
        valid = false;
      }


      var files = modalDOM.querySelector("#file")
      var file = null;

      if(files.files.length > 0){
        file = files.files[0];
      }

      const that = this;
      if (valid) {
        if (Number(id) !== -1) {
          //EDIT MODE
          var result1 = that.editEntry(id, bereich, bezeichnung, titel, beschreibung, file);
          if(!result1){
            modalDOM.querySelector("#file").classList.add("is-invalid");
          }else{
            modal.hide();
          }
        } else {
          //ADD MODE
          var result2 = await that.addEntry(bereich, bezeichnung, titel, beschreibung, file);
          if(!result2){
            modalDOM.querySelector("#file").classList.add("is-invalid");
          }else{
            modal.hide();
          }
        }
      }

    },

    clearValidationEditEntryDialog: function () {
      var modalDOM = document.getElementById("editElementModalFileManager");

      modalDOM.querySelector("#filetitel").classList.remove("is-valid");
      modalDOM.querySelector("#filetitel").classList.remove("is-invalid");

      modalDOM.querySelector("#filebezeichnung").classList.remove("is-valid");
      modalDOM.querySelector("#filebezeichnung").classList.remove("is-invalid");

      modalDOM.querySelector("#filebereich").classList.remove("is-valid");
      modalDOM.querySelector("#filebereich").classList.remove("is-invalid");

      modalDOM.querySelector("#file").classList.remove("is-valid");
      modalDOM.querySelector("#file").classList.remove("is-invalid");
    },

  }
}
</script>

<style scoped>

</style>