import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.esm.min"
import "bootstrap-icons/font/bootstrap-icons.css"
import "./main.css"

import "./theme.css"
import "./offcanvas-menu.css"
import "./scrollbar.css"



import * as bootstrap from "bootstrap/dist/js/bootstrap.esm.min"
window.bootstrap = bootstrap;


import {createWebHistory, createRouter} from 'vue-router'
import {createApp} from 'vue'
import App from './App.vue'

import HomeView from "@/components/views/HomeView.vue";
import KontaktView from "@/components/views/KontaktView.vue";
import OrdinationView from "@/components/views/OrdinationView.vue";
import ImpressumView from "@/components/views/ImpressumView.vue";
import AgbView from "@/components/views/AgbView.vue";
import ErrorNotFoundView from "@/components/views/404.vue";
import TeamView from "@/components/views/TeamView.vue";
import TermineView from "@/components/views/termin/TermineView.vue";
import BefundeView from "@/components/views/befund/BefundeView.vue";
import LeistungKasseView from "@/components/views/leistungen/kasse/LeistungKasseView.vue";
import LeistungPrivatView from "@/components/views/leistungen/privat/LeistungPrivatView.vue";
import LeistungAsthmaschulungView from "@/components/views/leistungen/LeistungAsthmaschulungView.vue";
import LeistungReanimationstrainingView from "@/components/views/leistungen/LeistungReanimationstrainingView.vue";
import LeistungPrivatImpfenView from "@/components/views/leistungen/privat/LeistungPrivatImpfenView.vue";
import LeistungKasseImpfenView from "@/components/views/leistungen/kasse/LeistungKasseImpfenView.vue";
import LeistungPrivatStrepATestView from "@/components/views/leistungen/privat/LeistungPrivatStrepATestView.vue";
import LeistungKasseBlutabnahmeView from "@/components/views/leistungen/kasse/LeistungKasseBlutabnahmeView.vue";
import leistungKasseUntersuchungView from "@/components/views/leistungen/kasse/LeistungKasseUntersuchungView.vue";
import leistungKasseAllergieView from "@/components/views/leistungen/kasse/LeistungKasseAllergieView.vue";
import leistungKasseImpfberatungView from "@/components/views/leistungen/kasse/LeistungKasseImpfberatungView.vue";
import leistungKasseMutterkindpassView from "@/components/views/leistungen/kasse/LeistungKasseMutterkindpassView.vue";
import AdminView from "@/components/views/admin/AdminView.vue";
import AdminLoginView from "@/components/views/admin/AdminLoginView.vue";

import {createPinia} from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import TermineViewVerwaltung from "@/components/views/termin/TermineViewVerwaltung.vue";



const routes = [
    {path: '/', name: "Home", component: HomeView},
    {path: '/ordination/', name: "Ordination", component: OrdinationView},
    {path: '/impressum/', name: "Impressum", component: ImpressumView},
    {path: '/kontakt/', name: "Kontakt", component: KontaktView},
    {path: '/agb/', name: "AGB", component: AgbView},

    {path: '/team/', name: "Team", component: TeamView},
    {path: '/team/gerhard/', name: "Dr. Gerhard Kusolitsch", component: TeamView},
    {path: '/leistungen/kasse/', name: "Kassenleistungen", component: LeistungKasseView},
    {path: '/leistungen/kasse/impfen/', name: "Impfen Kasse", component: LeistungKasseImpfenView},
    {path: '/leistungen/kasse/blutabnahme/', name: "Blutabnahme", component: LeistungKasseBlutabnahmeView},
    {path: '/leistungen/kasse/untersuchung/', name: "Allgemeine Untersuchungen", component: leistungKasseUntersuchungView},
    {path: '/leistungen/kasse/allergie/', name: "Allergie", component: leistungKasseAllergieView},
    {path: '/leistungen/kasse/impfberatung/', name: "Impfberatung", component: leistungKasseImpfberatungView},
    {path: '/leistungen/kasse/mutterkindpass/', name: "Mutterkindpass", component: leistungKasseMutterkindpassView},
    {path: '/leistungen/privat/', name: "Privatleistungen", component: LeistungPrivatView},
    {path: '/leistungen/privat/impfen/', name: "Impfen Privat", component: LeistungPrivatImpfenView},
    {path: '/leistungen/privat/strepatest/', name: "Strep A Test", component: LeistungPrivatStrepATestView},
    {path: '/leistungen/asthmaschulung/', name: "Asthmaschulung", component: LeistungAsthmaschulungView},
    {path: '/leistungen/reanimationstraining/', name: "Reanimationstraining", component: LeistungReanimationstrainingView},

    {path: '/termine/', name: "Terminvergabe", component: TermineView},
    {path: '/termine/accept/:id/:token', name: "Termin annehmen", component: TermineViewVerwaltung},
    {path: '/termine/decline/:id/:token', name: "Termin ablehnen", component: TermineViewVerwaltung},

    {path: '/befunde/', name: "Befundabfrage", component: BefundeView},



    {path: '/admin/', name: "Adminbereich", component: AdminView},
    {path: '/admin/login/', name: "Adminbereich Login", component: AdminLoginView},

    { path: '/:pathMatch(.*)', name: '404', component: ErrorNotFoundView },
    { path: '/:pathMatch(.*)*', name: '404', component: ErrorNotFoundView },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0, behavior: 'smooth' }
    },
});


const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)



const app = createApp(App);
app.use(pinia);
app.use(router);

app.mount('#app');
