<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Team</h1>
        <div class="divider"><span class="bg-green radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>




    <div class="row card-stack">
      <div class="col-12 col-md-6 col-lg-4 col-xl-3">
        <div class="card shadow text-center card-light">
          <div class="card-body">
            <img :src="getImgUrl('team/gerhard.png')" style="width:80%; border-radius: 50%; margin-top: 40px; margin-bottom: 40px; border: solid #6ab78a 5px">
            <h4 style="color:#404547!important;">Dr. Gerhard Kusolitsch</h4>
            <h5 style="color:#404547!important;">Kinderarzt</h5>
            <p class="card-text">Nach Beendigung des Studiums an der medizinischen Fakultät der Universität Wien ... (<a href="#" data-bs-toggle="modal" data-bs-target="#modalGerhard" class="green">mehr</a>)</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4 col-xl-3">
        <div class="card shadow text-center card-light">
          <div class="card-body">
            <img :src="getImgUrl('team/martina.png')" style="width:80%; border-radius: 50%; margin-top: 40px; margin-bottom: 40px; border: solid #6ab78a 5px">
            <h4 style="color:#404547!important;">Martina Krondorfer</h4>
            <h5 style="color:#404547!important;">Assistentin</h5>
            <p class="card-text">Seit Jänner 2023 kümmert sich Frau Krondorfer um die administrativen Bereiche in ... (<a href="#" data-bs-toggle="modal" data-bs-target="#modalMartina" class="green">mehr</a>)</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4 col-xl-3">
        <div class="card shadow text-center card-light">
          <div class="card-body">
            <img :src="getImgUrl('team/sonja.png')" style="width:80%; border-radius: 50%; margin-top: 40px; margin-bottom: 40px; border: solid #6ab78a 5px">
            <h4 style="color:#404547!important;">Sonja Kleindeßner</h4>
            <h5 style="color:#404547!important;">Assistentin</h5>
            <p class="card-text">Als Ordinationsassistentin unterstützt mich Frau Kleindeßner seit April 2019 ... (<a href="#" data-bs-toggle="modal" data-bs-target="#modalSonja" class="green">mehr</a>)</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4 col-xl-3">
        <div class="card shadow text-center card-light">
          <div class="card-body">
            <img :src="getImgUrl('team/bianca.png')" style="width:80%; border-radius: 50%; margin-top: 40px; margin-bottom: 40px; border: solid #6ab78a 5px">
            <h4 style="color:#404547!important;">Bianca Oppenauer</h4>
            <h5 style="color:#404547!important;">Assistentin</h5>
            <p class="card-text">Seit Jänner 2017 verstärkt Frau Oppenauer wieder unser Team und unterstützt ... (<a href="#" data-bs-toggle="modal" data-bs-target="#modalBianca" class="green">mehr</a>)</p>
          </div>
        </div>
      </div>
    </div>



    <div class="modal fade" id="modalBianca" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Bianca Oppenauer</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Seit Jänner 2017 verstärkt Frau Oppenauer wieder unser Team und unterstützt mich tatkräftig bei der kinderärztlichen Routinearbeit. Zusätzlich kümmert sie sich auch um administrative Angelegenheiten.
          </div>
          <div class="modal-footer">
            <a class="button shadow medium bg-green" data-bs-target="#modalBianca" data-bs-toggle="modal">Zurück</a>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="modalSonja" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Sonja Kleindeßner</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Als Ordinationsassistentin unterstützt mich Frau Kleindeßner seit April 2019 tatkräftig sowohl in der Administration als auch in der kinderärztlichen Routinearbeit.
          </div>
          <div class="modal-footer">
            <a class="button shadow medium bg-green" data-bs-target="#modalSonja" data-bs-toggle="modal">Zurück</a>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="modalMartina" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Martina Krondorfer</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Seit Jänner 2023 kümmert sich Frau Krondorfer um die administrativen Bereiche in der Ordination und sorgt damit für den reibungslosen Ablauf im Praxisalltag. Zusätzlich unterstützt sie mich bei verschiedenen medizinischen Handlungen.
          </div>
          <div class="modal-footer">
            <a class="button shadow medium bg-green" data-bs-target="#modalMartina" data-bs-toggle="modal">Zurück</a>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="modalGerhard" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Dr. Gerhard Kusolitsch</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Nach Beendigung des Studiums an der medizinischen Fakultät der Universität Wien begann ich im
              Dezember 1994 meine Ausbildung zum praktischen Arzt im Landesklinikum Amstetten. Nach Abschluss
              dieses Turnusdienstes und dem damit verbundenen Erlangen des „ius practicandi“ im September 1998
              startete ich ebenfalls in Amstetten die Facharztausbildung. Seit Jänner 2003 betreue ich nun als
              Facharzt für Kinder- und Jugendheilkunde die kleinen Patienten. Schon während meiner
              Assistenzzeit galt mein Interesse Kindern mit allergischen Erkrankungen, in meiner 8-jährigen
              Dienstzeit als Oberarzt an der Kinderabteilung Amstetten leitete ich schließlich die
              allergologische Ambulanz. Neben meiner Tätigkeit als Spitalsarzt habe ich 6 Jahre eine
              Wahlarztordination in Ulmerfeld-Hausmening geführt. 2011 verlegte ich die Ordination nach St.
              Valentin und beendete gleichzeitig meine Krankenhaustätigkeit. Mit Jänner 2014 wurde mir seitens
              der Niederösterreichischen Gebietskrankenkasse die Kassenstelle in St. Valentin
              zugesprochen.</p>

            <span class="green">Zusatzausbildungen:</span>
            <ul>
              <li>DFP-Diplom für pädiatrische Pneumologie und Allergologie</li>
              <li>Fortbildungsdiplom der Österreichischen Ärztekammer</li>
              <li>Diplom der Österreichischen Ärztekammer für Akupunktur</li>
            </ul>


          </div>
          <div class="modal-footer">
            <a class="button shadow medium bg-green" data-bs-target="#modalGerhard" data-bs-toggle="modal">Zurück</a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'TeamView',
  methods:{
    getImgUrl: function(image) {
      return require('../../assets/' + image)
    }
  },
}
</script>

<style scoped>


</style>
