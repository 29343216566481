<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Allgemeine Untersuchungen</h1>
        <div class="divider"><span class="bg-yellow radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <p>Eben noch gesund, im nächsten Augenblick hochfiebernd und krank. Bei Kindern geht das meist sehr
          rasch. Als Kinderarzt versuche ich ein breites Spektrum an Krankheiten abzudecken. Das reicht
          beispielsweise von leichten Erkältungskrankheiten bis hin zu hochfieberhaften Infekten, von
          Verstopfung bis zur Abklärung beim Einnässen oder von der Gedeihstörung bis zum Übergewicht.
          Allerdings hat sich die Kinderheilkunde mittlerweile zu einem riesigen Fachgebiet entwickelt,
          wodurch es praktisch unmöglich geworden ist, alle Bereiche abzudecken. Deshalb erachte ich es
          für wichtig, Kinder, deren Beschwerden ich nicht sicher einordnen kann, beim jeweiligen
          Spezialisten – das können Kinderärzte mit anderen Spezialausbildungen oder auch Ärzte anderer
          Fachgebiete sein – vorzustellen. Letztendlich ist es meines Erachtens nicht wichtig, wer die
          richtige Diagnose gestellt hat, sondern, dass Ihr Kind eine adäquate Therapie erhält.
        </p>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: 'LeistungKasseUntersuchungView',
}
</script>

<style scoped>


</style>
