<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Herzlich willkommen</h1>
        <div class="divider"><span class="bg-red radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>


    <div class="row">
      <div class="col-12 col-md-7">
        <p>
          Ich freue mich, Sie in meiner Kinderarztpraxis begrüßen zu dürfen. „Kinder sind keine kleinen
          Erwachsenen“ – sie denken anders, verstehen anders, fühlen anders und sehen die Welt mit anderen
          Augen als wir Erwachsenen. Als Facharzt für Kinder- und Jugendheilkunde ist es mir daher ein
          großes Anliegen, diesen Anforderungen gerecht zu werden und Ihr Kind bestmöglich zu betreuen. Zu
          Beginn meiner Ausbildung habe ich mir zum Ziel gesetzt, alle Kinder so zu behandeln, als wären
          sie meine eigenen. Diesem Motto bin ich bis heute treu geblieben und möchte das auch in Zukunft
          so tun. Letztendlich erhalten Sie von mir nur Therapieempfehlungen, die Entscheidung über deren
          Umsetzung liegt bei den Eltern. Aber es sind immer Empfehlungen, von den ich überzeugt bin, dass
          sie dem Wohle Ihres Kindes dienen. In diesem Sinn bedanke ich mich für Ihr Vertrauen und freue
          mich darauf, Ihr Kind ein Stück seines Weges begleiten zu dürfen.
        </p>
      </div>

      <div class="col-12 col-md-5">
        <div class="card bg-grey shadow">
          <div class="card-body">
            <h5 class="card-title bg-red">Aktuelles <i class="bi bi-calendar-event-fill"></i></h5>

            <ul v-if="sortierteTermine.length > 0" class="list">
              <li v-for="(termin, index) in sortierteTermine" v-bind:key="termin.id">
                <div>
                  <div style="width:60px; float:left;" class="center">
                    <div class="bg-dark radius-top padding-8">
                      <h2 class="white margin-0">{{ new Date(termin.startdatum).getDate() }}</h2>
                    </div>
                    <div class="bg-red radius-bottom padding-8 shadow">
                      <h6 class="white margin-0">{{ termin.month }}</h6>
                    </div>
                  </div>

                  <div class="marginleft-80" style="min-height: 90px">
                    <h4 class="dark">{{ termin.typ }}</h4>

                    <div class="space-5"></div>
                    <span>{{ termin.text }}</span>
                  </div>
                </div>
                <div v-if="sortierteTermine.length - 1 > index" class="clear space-16"></div>
              </li>
            </ul>

            <ul v-if="sortierteTermine.length === 0" class="list">
              <li>
                <div>
                  <div style="width:60px; float:left;" class="center">
                    <div class="bg-dark radius-top padding-8">
                      <h2 class="white margin-0"><i class="bi bi-clipboard-check-fill"></i></h2>
                    </div>
                    <div class="bg-red radius-bottom padding-8 shadow">
                      <h6 class="white margin-0">{{ formatMonth(new Date().getMonth()) }}</h6>
                    </div>
                  </div>

                  <div class="marginleft-80">
                    <h4 class="dark">Keine neuen Termine</h4>

                    <div class="space-5"></div>
                    <span>Aktuell gibt es keine neuen Termine</span>
                  </div>
                  <div class="space-10"></div>
                </div>
              </li>
            </ul>

          </div>
        </div>

        <div class="space-20"></div>

        <div class="card bg-grey shadow">
          <div class="card-body">
            <h5 class="card-title bg-red">Dokumente & Informationen <i class="bi bi-info-circle-fill"></i></h5>

            <ul class="list">
              <FileRenderer :files="dokumente" color="red" location="Home"/>
            </ul>
          </div>
        </div>

        <div class="space-20"></div>


      </div>

    </div>
  </div>
</template>

<script>
import FileRenderer from "@/components/FileRenderer.vue";

import { useDocumentsStore } from '@/stores/documents'
import {useTermineStore} from "@/stores/termine";


export default {
  name: 'HomeView',
  components: {FileRenderer},

  computed: {
    sortierteTermine() {
      let elements = [];
      if (this.termine.length > 0) {
        elements = this.termine.filter(item => new Date(new Date(item.enddatum).getTime() + 60 * 60 * 24 * 1000) >= new Date()).sort((a, b) => {
          return new Date(a.date) - new Date(b.date)
        });
      }

      for (var i = 0; i < elements.length; i++) {
        elements[i]["month"] = this.formatMonth(new Date(elements[i].startdatum).getMonth());
      }

      return elements;
    },

    termine(){
      return useTermineStore().getTermine
    },

    dokumente(){
      return useDocumentsStore().getDocuments
    }
  },

  methods:{
    formatMonth: function(monthIndex){
      var months = ["JAN", "FEB", "MÄR", "APR", "MAI", "JUN",
        "JUL", "AUG", "SEP", "OKT", "NOV", "DEZ"];
      return months[monthIndex];
    }
  }
}
</script>

<style scoped>


</style>
