<template>
  <div class="container">
    <div class="row">
      <div class="col-12 mb-2">
        <h4>Befundverwaltung</h4>
      </div>
    </div>


    <div class="row mb-5">
      <div class="col-12">
        <table class="table table-hover " style="width: 100%">
          <thead>
          <tr class="table-dark">
            <th scope="col" style="width: 150px;">Vorname</th>
            <th scope="col" style="width: 150px;">Nachname</th>
            <th scope="col" style="width: 150px;">Geburtsdatum</th>
            <th scope="col" style="width: 70px;">SVNR</th>
            <th scope="col">Token</th>
            <th scope="col" style="width: 110px;"></th>
          </tr>
          </thead>
          <tbody class="">

          <tr v-for="item in befunde" v-bind:key="item.id">
            <td class="align-middle">{{ item.vorname }}</td>
            <td class="align-middle">{{ item.nachname }}</td>
            <td class="align-middle">{{ item.geburtsdatum }}</td>
            <td class="align-middle">{{ item.svnr }}</td>
            <td class="align-middle">{{ item.token }}</td>
            <td>
              <a @click="showFile(item.id)" target="_blank" type="button" class="btn btn-light me-2"><i
                  class="bi violet bi-eye-fill"></i></a>
              <button @click="deleteEntry(item.id)" type="button" class="btn btn-light"><i
                  class="bi violet bi-trash-fill"></i></button>
            </td>
          </tr>
          </tbody>
        </table>


        <div class="d-flex flex-row-reverse">

          <button type="button" class="btn btn-light" @click="openAddEntryDialog()"><i
              class="bi violet bi-plus-circle-fill"></i></button>

          <button type="button" class="btn btn-light me-1" @click="refresh()"><i
              class="bi violet bi-arrow-clockwise"></i></button>
        </div>


      </div>
    </div>


    <div class="modal" tabindex="-1" id="editElementModalBefundManager">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Befundeintrag erstellen</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div class="mb-2 row">
              <label for="vorname" class="col-sm-4 col-form-label">Vorname</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" id="vorname" v-model="vorname">
                <div class="invalid-feedback">
                  Angaben sind erforderlich
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="nachname" class="col-sm-4 col-form-label">Nachname</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" id="nachname" v-model="nachname">
                <div class="invalid-feedback">
                  Angaben sind erforderlich
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="geburtsdatum" class="col-sm-4 col-form-label">Geburtsdatum</label>
              <div class="col-sm-8">
                <input type="date" class="form-control" id="geburtsdatum" v-model="geburtsdatum">
                <div class="invalid-feedback">
                  Das Datum kann nicht in der Zukunft liegen
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="svnr" class="col-sm-4 col-form-label">SVNR</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" id="svnr" v-model="svnr">
                <div class="invalid-feedback">
                  Angaben sind erforderlich (SVNR: XXXX)
                </div>
              </div>
            </div>

            <hr>

            <div class="mb-2 row">
              <label for="email" class="col-sm-4 col-form-label">E-Mail</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" id="email" v-model="email">
                <div class="invalid-feedback">
                  Angaben sind erforderlich
                </div>
              </div>
            </div>

            <hr>

            <div class="mb-2 row">
              <label for="file" class="col-sm-4 col-form-label">Datei</label>
              <div class="col-sm-8">
                <div class="input-group">
                  <input type="file" class="form-control" id="file" :value="file">
                  <div class="invalid-feedback">
                    Datei konnte nicht hochgeladen werden
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a class="button shadow medium bg-greydark" data-bs-dismiss="modal">Close</a>
            <a type="submit" class="button shadow medium bg-violet" @click="validateEditEntryDialog()">Speichern</a>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>


<script>


import {useAdminStore} from "@/stores/admin";
import api from "@/axios";

export default {
  name: 'BefundManager',
  computed: {
    befunde(){
      return useAdminStore().getBefunde;
    }
  },


  data: () => ({
    vorname: "",
    nachname: "",
    email: "",
    svnr: "",
    geburtsdatum: "",
    file: "",
  }),

  methods: {
    showFile: async function (id) {
      try {
        const response = await api.get("/befunde/" + id, {
          responseType: 'blob',
        });
        if (response.status === 200) {
          const downloadUrl = window.URL.createObjectURL(response.data);
          window.open(downloadUrl, '__blank');
        }
      } catch (error) {
        error;
      }
    },

    refresh: function(){
      useAdminStore().fetchBefunde();
    },

    deleteEntry: function (id) {
      useAdminStore().deleteBefund(id);
    },

    openAddEntryDialog: function () {
      var modalDOM = document.getElementById("editElementModalBefundManager");
      var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);

      this.vorname = "";
      this.nachname = "";
      this.email = "";
      this.svnr = "";
      this.geburtsdatum = "";
      this.file = "";

      this.clearValidationEditEntryDialog();
      modal.show();
    },


    validateEditEntryDialog: async function () {
      this.clearValidationEditEntryDialog();
      var modalDOM = document.getElementById("editElementModalBefundManager");
      var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);

      var valid = true;


      if (this.vorname.length > 0) {
        modalDOM.querySelector("#vorname").classList.add("is-valid");
      } else {
        modalDOM.querySelector("#vorname").classList.add("is-invalid");
        valid = false;
      }

      if (this.nachname.length > 0) {
        modalDOM.querySelector("#nachname").classList.add("is-valid");
      } else {
        modalDOM.querySelector("#nachname").classList.add("is-invalid");
        valid = false;
      }

      if (this.email.length > 0 && String(this.email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )) {
        modalDOM.querySelector("#email").classList.add("is-valid");
      } else {
        modalDOM.querySelector("#email").classList.add("is-invalid");
        valid = false;
      }

      if (this.svnr.length === 4 && !isNaN(this.svnr)) {
        modalDOM.querySelector("#svnr").classList.add("is-valid");
      } else {
        modalDOM.querySelector("#svnr").classList.add("is-invalid");
        valid = false;
      }

      if(!isNaN(new Date(this.geburtsdatum)) && (new Date(this.geburtsdatum) <= new Date())) {
        modalDOM.querySelector("#geburtsdatum").classList.add("is-valid");
      }else{
        modalDOM.querySelector("#geburtsdatum").classList.add("is-invalid");
        valid = false;
      }


      var files = modalDOM.querySelector("#file")
      var file = null;

      if(files.files.length > 0){
        file = files.files[0];
        modalDOM.querySelector("#file").classList.add("is-valid");
      }else{
        modalDOM.querySelector("#file").classList.add("is-invalid");
        valid = false;
      }

      if (valid) {
        var result = await useAdminStore().createBefund(this.vorname, this.nachname, this.geburtsdatum, this.svnr, this.email, file)
        if(!result){
          modalDOM.querySelector("#file").classList.add("is-invalid");
        }else{
          modal.hide();
        }
      }

    },

    clearValidationEditEntryDialog: function () {
      var modalDOM = document.getElementById("editElementModalBefundManager");

      modalDOM.querySelector("#vorname").classList.remove("is-valid");
      modalDOM.querySelector("#vorname").classList.remove("is-invalid");

      modalDOM.querySelector("#nachname").classList.remove("is-valid");
      modalDOM.querySelector("#nachname").classList.remove("is-invalid");

      modalDOM.querySelector("#geburtsdatum").classList.remove("is-valid");
      modalDOM.querySelector("#geburtsdatum").classList.remove("is-invalid");

      modalDOM.querySelector("#svnr").classList.remove("is-valid");
      modalDOM.querySelector("#svnr").classList.remove("is-invalid");

      modalDOM.querySelector("#email").classList.remove("is-valid");
      modalDOM.querySelector("#email").classList.remove("is-invalid");

      modalDOM.querySelector("#file").classList.remove("is-valid");
      modalDOM.querySelector("#file").classList.remove("is-invalid");
    },

  }
}
</script>

<style scoped>

</style>