<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Strep-A-Test</h1>
        <div class="divider"><span class="bg-yellow radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>


    <div class="row">
      <div class="col-12 col-md-7">
        <p>Angina oder Scharlach können durch Streptokokken der Gruppe A ausgelöst werden. Vor allem zu
          Beginn der Erkrankung kann bei Kindern das klinische Bild nicht immer eindeutig sein. In diesem
          Fall kann mit Hilfe des Schnelltests frühzeitig die Infektion nachgewiesen werden und eine
          entsprechende Therapie – ein Antibiotikum mit schmälerem Wirkspektrum ist dann meist ausreichend
          – eingeleitet werden.
        </p>
      </div>

      <div class="col-12 col-md-5">
        <div class="card bg-grey shadow">
          <div class="card-body">
            <h5 class="card-title bg-yellow">Informationen<i class="bi bi-info-circle-fill"></i></h5>
            <ul class="list">
              <li>
                <div>
                  <div style="width:60px; float:left;" class="center">
                    <div class="bg-dark radius-top padding-8">
                      <h2 class="white margin-0"><i class="bi-currency-euro"></i></h2>
                    </div>
                    <div class="bg-yellow radius-bottom padding-8 shadow">
                      <h6 class="white margin-0">7,00</h6>
                    </div>
                  </div>

                  <div class="marginleft-80" style="min-height: 90px">
                    <h4 class="dark">Kosten</h4>
                    <div class="space-5"></div>
                    <span>Testgebühr</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="space-20"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeistungPrivatStrepATestView',
}
</script>

<style scoped>


</style>
