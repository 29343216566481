<template>
  <div>
    <header>
      <div class="container position-absolute start-50 translate-middle-x" style="z-index: 1">
        <div class="row d-none d-md-block">
          <nav class="navbar navbar-expand-md navbar-dark bg-dark" style="padding-top: 0px; padding-bottom: 0px;">
            <div class="container-fluid">

              <ul class="navbar-nav top-menu">
                <li class="nav-item">
                  <a class="nav-link" href="tel:+437435/52919" style="font-weight:bold"><i class="bi bi-telephone-fill"></i> 07435/52919</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="mailto:kinderarztkusolitsch@aon.at" style="font-weight:bold"><i class="bi bi-envelope-fill"></i>
                    kinderarztkusolitsch@aon.at</a>
                </li>
              </ul>

              <ul class="navbar-nav ms-auto mb-2 mb-md-0 top-menu">
                <li class="nav-item">
                  <router-link class="nav-link" to="/befunde" style="font-weight:bold"><i class="bi bi-file-medical-fill"></i> BEFUNDE</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="/termine" style="font-weight:bold"><i class="bi bi-calendar-fill"></i> TERMINE</router-link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div class="row rainbow-bar"></div>


        <div class="row">
          <nav class="navbar navbar-expand-md navbar-light bg-light main-menu">
            <div class="container-fluid">
              <router-link class="navbar-brand" to="/" style="color: #495052!important;">
                <img src="./assets/logo.svg" width="50" height="40" class="d-inline-block align-text-top"
                     style="margin-top:-5px"> <span class="d-md-none">Kinderarzt Kusolitsch</span>
              </router-link>

              <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
                <span class="navbar-toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse " id="navbarCollapse">
                <ul class="navbar-nav ms-auto mb-2 mb-md-0 menu">

                  <li v-for="(item, index) in menuItems.filter(item => item.onlyMobile !== true)" v-bind:key="index" class="nav-item" :class="[
                      {'active':((item.pathMatch === true && $route.path === item.path) || (item.pathMatch !== true && $route.path.includes(item.path)))},
                      item.color,
                      {'dropdown': item.subElements.length > 0}]">
                    <router-link v-if="item.subElements.length === 0" class="nav-link" :to="item.path">{{item.name}}</router-link>

                    <a v-if="item.subElements.length > 0" class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                       aria-expanded="false">
                      {{item.name}}
                    </a>

                    <ul v-if="item.subElements.length > 0" class="dropdown-menu sub-menu">
                      <li v-for="(subitem, index) in item.subElements" v-bind:key="index"><router-link class="dropdown-item" :to="subitem.path">
                        {{subitem.name}}</router-link></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>


      <div class="offcanvas offcanvas-end text-bg-light" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">Kinderarzt Kusolitsch</h5>
          <button type="button" class="btn-close btn-close-dark" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <nav class="bd-links">
            <ul class="bd-links-nav list-unstyled mb-0 pb-3 pb-md-2 pe-lg-2">

              <li v-for="(item, index) in menuItems"  v-bind:key="index" class="bd-links-group py-2">
                <strong class="bd-links-heading d-flex w-100 align-items-center fw-semibold">
                  <i class="bi" :class="[item.color, item.icon]"></i>&nbsp;&nbsp;{{item.name}}
                </strong>
                <ul class="list-unstyled fw-normal pb-2 small">
                  <li v-if="item.subElements.length === 0">
                    <router-link :to="item.path" class="bd-links-link d-inline-block rounded"  @click="closeOffCanvas('offcanvasDarkNavbar')">{{ item.name }}</router-link>
                  </li>

                  <li v-for="(subitem, index) in item.subElements" v-bind:key="index" >
                    <router-link :to="subitem.path" class="bd-links-link d-inline-block rounded" @click="closeOffCanvas('offcanvasDarkNavbar')">{{ subitem.name }}</router-link>
                  </li>
                </ul>
              </li>


            </ul>
          </nav>


        </div>
      </div>


      <div id="myCarousel" class="carousel slide" style="z-index: 0" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class="active" aria-current="true"
                  aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 4"></button>
          <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="3" aria-label="Slide 5"></button>
          <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="4" aria-label="Slide 6"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img alt="Vue logo" src="./assets/slider/image7.jpg"
                 style="width: 100%; height: 500px; object-fit: cover;">

          </div>
          <div class="carousel-item">
            <img alt="Vue logo" src="./assets/slider/image5.jpg"
                 style="width: 100%; height: 500px; object-fit: cover;">
          </div>


          <div class="carousel-item">
            <img alt="Vue logo" src="./assets/slider/image4.jpg"
                 style="width: 100%; height: 500px; object-fit: cover;">
          </div>

          <div class="carousel-item">
            <img alt="Vue logo" src="./assets/slider/image2.jpg"
                 style="width: 100%; height: 500px; object-fit: cover;">
          </div>

          <div class="carousel-item">
            <img alt="Vue logo" src="./assets/slider/image8.jpg"
                 style="width: 100%; height: 500px; object-fit: cover;">
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </header>

    <main style="min-height: calc(100vh - 50px - 500px); padding-top: 20px">
      <div class="container">

        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>

      </div>
    </main>

    <footer>
      <nav class="navbar navbar-expand navbar-dark dark bg-dark"
           style="padding-top: 0px; padding-bottom: 0px;height: 50px">
        <div class="container-fluid">
        <span class="navbar-brand">
          <img src="./assets/copyright.jpg" alt="" width="30" height="30" class="d-inline-block align-text-top"> <span
            style="font-size: 1rem; margin-left: -15px;">&copy; M. Kusolitsch 2024</span>
        </span>

          <ul class="navbar-nav ms-auto mb-sm-0 top-menu">
            <li class="nav-item">
              <router-link class="nav-link" to="/agb">AGB</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/impressum">IMPRESSUM</router-link>
            </li>
          </ul>
        </div>
      </nav>
    </footer>
  </div>
</template>

<script>


import { useDocumentsStore } from '@/stores/documents'
import {useTermineStore} from "@/stores/termine";
import {useImpfstoffeStore} from "@/stores/impfstoffe";
import {useOrdinationszeitenStore} from "@/stores/ordinationszeiten";
import {useAdminStore} from "@/stores/admin";



export default {
  name: 'App',
  computed: {

  },
  setup: function(){
    useDocumentsStore().fetch();
    useTermineStore().fetch();
    useImpfstoffeStore().fetch();
    useOrdinationszeitenStore().fetch();
    useAdminStore().fetch();

    setInterval(function() {
      useDocumentsStore().fetch();
      useTermineStore().fetch();
      useImpfstoffeStore().fetch();
      useOrdinationszeitenStore().fetch();
      useAdminStore().fetch();
    }, 60000);
  },

  methods:{
    closeOffCanvas: function(id){
      document.querySelector("#" + id + " > div > .btn-close").click();
    }
  },
  data: () => ({
    menuItems:[
      {name: "Home", color: "red", path: "/", pathMatch: true, subElements: [], icon: "bi-house-door-fill"},
      {name: "Leistungen", color: "yellow", path: "/leistungen/", subElements: [
          {name: "Kassenleistungen", color: "yellow", path: "/leistungen/kasse/", subElements: []},
          {name: "Privatleistungen", color: "yellow", path: "/leistungen/privat/", subElements: []},
          {name: "Asthmaschulung", color: "yellow", path: "/leistungen/asthmaschulung/", subElements: []},
          {name: "Reanimationstraining", color: "yellow", path: "/leistungen/reanimationstraining/", subElements: []},
        ], icon: "bi-clipboard2-pulse-fill"},

      {name: "Team", color: "green", path: "/team/", subElements: [], icon: "bi-people-fill"},
      {name: "Ordination", color: "orange", pathMatch: true, path: "/ordination/", subElements: [], icon: "bi-heart-pulse-fill"},
      {name: "Kontakt", color: "blue", pathMatch: true, path: "/kontakt/", subElements: [], icon: "bi-envelope-fill"},

      {name: "Befunde", color: "violet", pathMatch: true, path: "/befunde/", subElements: [], onlyMobile: true, icon: "bi-file-medical-fill"},
      {name: "Termine", color: "green", pathMatch: true, path: "/termine/", subElements: [], onlyMobile: true, icon: "bi-calendar-fill"},
    ],
  }),
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        from;
        if(to.name != null){
          document.title = to.name + ' - Kinderarzt Kusolitsch';
        }else{
          document.title = 'Kinderarzt Kusolitsch';
        }
      }
    },
  }
}
</script>

<style>

</style>
