<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Befundabfrage</h1>
        <div class="divider"><span class="bg-violet radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <p>Hier können Befunde digital mithilfe des Codes, den sie erhalten haben, abgerufen werden. Bitte beachten Sie, dass jeder Token nur <b class="violet">EINMAL</b> gültig ist.</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2>Patientendaten</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="form-label" for="svnr">Sozial Versicherungs Nummer des Kindes</label>
          <input type="text" class="form-control" id="svnr" v-model="svnr">
          <div class="invalid-feedback">
            SVNR: XXXX
          </div>
        </div>
        <div class="space-20"></div>
      </div>

      <div class="col-xs-12 col-md-6">
        <div class="form-group">
          <label class="form-label" for="geburtstagkind">Geburtsdatum des Kindes</label>
          <input type="date" class="form-control" id="geburtstagkind" v-model="geburtsdatum">
          <div class="invalid-feedback">
            Das Datum kann nicht in der Zukunft liegen
          </div>
        </div>
        <div class="space-20"></div>
      </div>

      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="form-label" for="code">Abfragecode</label>
          <input type="text" class="form-control" id="code" v-model="token">
          <div class="invalid-feedback" id="feedback-token">
            Token ungültig
          </div>
        </div>
        <div class="space-20"></div>
      </div>
    </div>


    <div class="col-12">
      <a class="button bg-violet  medium shadow" @click="abfragen">Befund anzeigen</a>
    </div>


    <div class="space-20"></div>
  </div>
</template>

<script>
import api from "@/axios";
import {useRoute} from "vue-router";

var route;

export default {
  name: 'BefundeView',

  data: () => ({
    svnr: "",
    token: "",
    geburtsdatum: ""
  }),

  setup(){
    route = useRoute();
  },

  mounted(){
    if(this.urlToken !== undefined){
      this.token = this.urlToken;
    }
  },

  computed: {
    urlToken(){
      return route.query.token;
    }
  },


  methods: {
    abfragen: async function () {
      this.clearValidations();


      var result = this.validate();
      const that = this;

      if (result) {
        try {
          const response = await api.post("/befunde/request", {
            token: that.token,
            geburtsdatum: that.geburtsdatum,
            svnr: that.svnr,
          }, {
            responseType: 'blob',
          });
          if (response.status === 200) {
            const downloadUrl = window.URL.createObjectURL(response.data);
            window.open(downloadUrl, '__blank');
          } else {
            document.querySelector("#code").classList.add("is-invalid");
            document.querySelector("#feedback-token").innerHTML = "Kombination aus Token, SVNR und Geburtsdatum ungültig";
          }
        } catch (error) {
          error;
          document.querySelector("#code").classList.add("is-invalid");
        }
      }
      console.log(result);
    },

    validate: function () {
      var valid = true;

      if (this.svnr.length === 4 && !isNaN(this.svnr)) {
        document.querySelector("#svnr").classList.add("is-valid");
      } else {
        document.querySelector("#svnr").classList.add("is-invalid");
        valid = false;
      }

      if (!isNaN(new Date(this.geburtsdatum)) && (new Date(this.geburtsdatum) <= new Date())) {
        document.querySelector("#geburtstagkind").classList.add("is-valid");
      } else {
        document.querySelector("#geburtstagkind").classList.add("is-invalid");
        valid = false;
      }

      if (this.token.length > 0) {
        document.querySelector("#code").classList.add("is-valid");
      } else {
        document.querySelector("#code").classList.add("is-invalid");
        valid = false;
      }

      return valid;
    },

    clearValidations: function () {
      document.querySelector("#svnr").classList.remove("is-valid");
      document.querySelector("#svnr").classList.remove("is-invalid");

      document.querySelector("#geburtstagkind").classList.remove("is-valid");
      document.querySelector("#geburtstagkind").classList.remove("is-invalid");

      document.querySelector("#code").classList.remove("is-valid");
      document.querySelector("#code").classList.remove("is-invalid");

      document.querySelector("#feedback-token").innerHTML = "Token ungültig";
    },
  }
}
</script>

<style scoped>


</style>
