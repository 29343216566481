<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Terminvergabe</h1>
        <div class="divider"><span class="bg-green radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>

    <div class="row" v-if="loadingAnimation">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <div class="spinner-border me-2" role="status" aria-hidden="true"></div>
          <strong>Bitte warten, Daten werden geladen...</strong>
        </div>
      </div>
    </div>

    <div class="row" v-if="byebye">
      <div class="col-12">
        <p>Auf Wiedersehen!</p>
      </div>
    </div>

    <div class="row" v-if="!loadingAnimation && !byebye">
      <div class="col-12 col-md-6 mb-4">


        <div class="card shadow">
          <img src="../../../assets/kalender.jpg" class="card-img-top" style="">
          <div class="card-body">
            <div class="card-text">
              <h5>Ihr Termin</h5>

              <hr>
              <div class="mb-2 row">
                <label class="col-4 col-xs-3 col-form-label">Vorname</label>
                <div class="col-8 col-xs-9">
                  <span class="form-control" style="border:none">{{ selectedTermin.vorname }}</span>
                </div>
              </div>

              <div class="mb-2 row">
                <label class="col-4 col-xs-3 col-form-label">Nachname</label>
                <div class="col-8 col-xs-9">
                  <span class="form-control" style="border:none">{{ selectedTermin.nachname }}</span>
                </div>
              </div>

              <hr>

              <div class="mb-2 row">
                <label class="col-4 col-xs-3 col-form-label">Datum</label>
                <div class="col-8 col-xs-9">
                  <span class="form-control"
                        style="border:none">{{ new Date(selectedTermin.termin_response).toLocaleDateString() }}</span>
                </div>
              </div>

              <div class="mb-2 row">
                <label class="col-4 col-xs-3 col-form-label">Uhrzeit</label>
                <div class="col-8 col-xs-9">
                  <span class="form-control" style="border:none">{{
                      new Date(selectedTermin.termin_response).toLocaleTimeString()
                    }} Uhr</span>
                </div>
              </div>

              <div class="mb-2 row" v-if="selectedTermin.anmerkung_response !== ''">
                <label class="col-4 col-xs-3 col-form-label">Anmerkung</label>
                <div class="col-8 col-xs-9">
                  <span class="form-control" style="border:none">{{ selectedTermin.anmerkung_response }}</span>
                </div>
              </div>

            </div>
            <a href="#" class="button shadow medium bg-green position-relative start-50 translate-middle-x"
               @click="processRequest()">{{ mode ? "Annehmen" : "Ablehnen" }}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" data-bs-backdrop="static" tabindex="-1" id="terminVergabeModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Termin storniert</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    @click="closeModal"></button>
          </div>
          <div class="modal-body">
            <div v-if="!modalPage1">
              <p>Ihre Terminanfrage wurde übermittelt.</p>


              <p>Sie erhalten ein E-Mail mit dem Termin. Sollten sie den Termin wahrnehmen wollen, bestätigen Sie diesen bitte
                durch Drücken des Buttons <b class="green">"Termin bestätigen"</b> in der E-Mail. Andernfalls drücken Sie bitte <b class="green">"Termin ablehnen"</b>, sodass der reservierte Termin wieder
                vergeben werden kann.</p>

              <p>Bitte stornieren Sie einen vereinbarten Termin rechtzeitig, wenn Sie diesen nicht wahrnehmen können!</p>
            </div>

            <div v-if="modalPage1">
              <p>
                Ihr Termin wurde storniert. Möchten Sie stattdessen einen neuen Termin anfragen?
              </p>

              <div class="mb-2 row">
                <label for="termin1" class="col-sm-3 col-form-label">Termin</label>
                <div class="col-sm-9">
                  <input type="date" class="form-control" id="termin1" v-model="termin1">
                  <div class="invalid-feedback" id="termindate1-feedback">
                    Ungültiges Datum
                  </div>
                </div>
              </div>

              <div class="mb-2 row">
                <label for="termin2" class="col-sm-3 col-form-label">Ersatztermin</label>
                <div class="col-sm-9">
                  <input type="date" class="form-control" id="termin2" v-model="termin2">
                  <div class="invalid-feedback" id="termindate2-feedback">
                    Ungültiges Datum
                  </div>
                </div>
              </div>
            </div>



          </div>
          <div class="modal-footer">
            <a class="button shadow medium bg-greydark" data-bs-dismiss="modal" @click="closeModal()" v-if="modalPage1">Nein</a>
            <a v-if="modalPage1" type="submit" class="button shadow medium bg-green" @click="ersatzTermin()">Ja, Ersatztermin
              anfragen</a>

            <a v-if="!modalPage1" type="submit" class="button shadow medium bg-green" data-bs-dismiss="modal" @click="closeModal()">OK</a>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import {useRoute} from "vue-router";
import api from "@/axios";
import {useTermineStore} from "@/stores/termine";
import {useOrdinationszeitenStore} from "@/stores/ordinationszeiten";

var route;

export default {
  name: 'TermineViewVerwaltung',
  data: () => ({
    loadingAnimation: true,
    byebye: false,
    selectedTermin: {},

    termin1: "",
    termin2: "",

    modalPage1: true,
  }),

  setup() {
    route = useRoute();
  },

  async mounted() {
    try {
      var result = await api.get("terminanfragen/" + this.terminID + "/" + this.terminToken, {})
      this.selectedTermin = result.data;
      this.loadingAnimation = false;
    } catch (e) {
      e;
    }
  },

  computed: {
    terminID() {
      return route.params.id;
    },

    terminToken() {
      return route.params.token;
    },

    mode() {
      var tmp = route.path.split("/");
      if (tmp.length === 5) {
        return tmp[2] === "accept";
      }

      return false;
    },
  },

  methods: {
    processRequest: async function () {
      var that = this;

      if (this.mode) {
        try {
          await api.post("terminanfragen/" + this.terminID + "/accept", {
            token: that.terminToken,
          });

          this.byebye = true;
        } catch (e) {
          e;
        }
      } else {

        try {
          await api.post("terminanfragen/" + this.terminID + "/decline", {
            token: that.terminToken,
          });

          var modalDOM = document.getElementById("terminVergabeModal");
          var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);
          that.modalPage1 = true;
          that.termin1 = "";
          that.termin2 = "";
          modal.show();

        } catch (e) {
          e;
        }

      }
    },

    closeModal: function () {
      this.byebye = true;
    },

    ersatzTermin: async function () {
      this.clearValidation();
      var valid = true;
      const that = this;

      if (!isNaN(new Date(this.termin1)) && (new Date(this.termin1) > new Date())) {

        if (this.validateDateUrlaub(this.termin1, "#termindate1-feedback") && this.validateOrdinationsZeiten(this.termin1, "#termindate1-feedback")) {
          document.querySelector("#termin1").classList.add("is-valid");
        } else {
          document.querySelector("#termin1").classList.add("is-invalid");
          valid = false;
        }

      } else {
        document.querySelector("#termin1").classList.add("is-invalid");
        document.querySelector("#termindate1-feedback").innerHTML = "Das Datum kann nicht in der Vergangenheit liegen";

        valid = false;
      }

      if (!isNaN(new Date(this.termin2)) && (new Date(this.termin2) > new Date())) {

        if (this.validateDateUrlaub(this.termin2, "#termindate2-feedback") && this.validateOrdinationsZeiten(this.termin2, "#termindate2-feedback")) {
          document.querySelector("#termin2").classList.add("is-valid");
        } else {
          document.querySelector("#termin2").classList.add("is-invalid");
          valid = false;
        }

      } else {
        document.querySelector("#termin2").classList.add("is-invalid");
        document.querySelector("#termindate2-feedback").innerHTML = "Das Datum kann nicht in der Vergangenheit liegen";

        valid = false;
      }


      if (valid) {
        try {
          await api.put("terminanfragen/" + this.terminID + "/" + this.terminToken + "/new", {
            termin1: that.termin1,
            termin2: that.termin2,
          });

          this.modalPage1 = false;
        } catch (e) {
          e;
        }
      }
    },

    clearValidation() {
      var modalDOM = document.getElementById("terminVergabeModal");

      modalDOM.querySelector("#termin1").classList.remove("is-valid");
      modalDOM.querySelector("#termin1").classList.remove("is-invalid");

      modalDOM.querySelector("#termin2").classList.remove("is-valid");
      modalDOM.querySelector("#termin2").classList.remove("is-invalid");
    },


    validateDateUrlaub(datum, selector) {
      var urlaube = useTermineStore().getTermine.filter(item => item.typ === "Urlaub");
      var kollisionen = urlaube.filter(item => new Date(item.startdatum) <= new Date(datum) && new Date(datum) <= new Date(item.enddatum));

      if (kollisionen.length === 0) {
        return true;
      } else {
        document.querySelector(selector).innerHTML = "Das Datum liegt in unserem Urlaub (" + new Date(kollisionen[0].startdatum).toLocaleDateString() + " - " + new Date(kollisionen[0].enddatum).toLocaleDateString() + "). Bitte wählen Sie einen anderen Tag.";
        return false;
      }
    },

    validateOrdinationsZeiten(datum, selector) {
      const wochentag = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
      var zeiten = useOrdinationszeitenStore().getZeiten;
      var ordinationszeiten = useOrdinationszeitenStore().getZeitenOrdination.map(item => item.tag).join(", ");
      var kollisionen = zeiten.filter(item => item.tag === wochentag[new Date(datum).getDay()]);

      if (kollisionen.length !== 0) {
        return true;
      } else {
        var announcements = useTermineStore().getTermine.filter(item => item.typ === "Ankündigung");
        var collisions = announcements.filter(item => new Date(item.startdatum) <= new Date(datum) && new Date(datum) <= new Date(item.enddatum));

        if (collisions.length !== 0) {
          return true;
        } else {
          document.querySelector(selector).innerHTML = "Das Datum liegt außerhalb unserer Ordinationszeiten (" + ordinationszeiten + "). Bitte wählen Sie einen anderen Tag.";
          return false;
        }
      }
    },

  }
}
</script>

<style scoped>

</style>
