<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Ordination</h1>
        <div class="divider"><span class="bg-orange radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>


    <div class="row">
      <div class="col-12 col-md-7">
        <p>Die Praxis liegt im Zentrum von St. Valentin in unmittelbarer Nähe zur St. Valentinus Apotheke. Zahlreiche
          Parkmöglichkeiten in der Umgebung ermöglichen ein rasches
          Erreichen der Ordinationsräumlichkeiten.</p>

        <p><b class="orange">Ich ersuche Sie für jedes Kind – auch im Krankheitsfall – einen Termin zu vereinbaren. (
          <router-link class="orange" to="/agb/">Weiter lesen</router-link>
          )</b> Im Normalfall erhält jedes kranke Kind am selben Tag einen Untersuchungstermin! Einerseits ist es
          gegenüber den
          Kindern mit Terminvereinbarung fair, wenn diese nicht längere Wartezeiten auf sich nehmen müssen.
          Andererseits ist es für Ihr Kind auch besser, zu Hause als im überfüllten Wartezimmer auf den
          Untersuchungstermin zu warten. Wenn Sie zum ersten Mal meine Ordination aufsuchen, kommen Sie bitte
          einige Minuten früher, da die Aufnahme der Patientendaten einige Zeit beansprucht. Sollten Sie einen
          Termin nicht wahrnehmen können, melden Sie sich bitte rechtzeitig in der Ordination, damit wir
          diesen an ein anderes Kind vergeben können. Ich bitte um Verständnis, dass die Reihenfolge der
          Patienten nicht der des Eintreffens in der Ordination entsprechen muss (z.B. Notfälle, verspätetes
          Erscheinen zum Termin). Für das Ausstellen von Rezepten oder Überweisungen ist keine
          Terminvereinbarung nötig.
        </p>

        <p>Im Bedarfsfall biete ich auch die Durchführung von Hausbesuchen an (eine Anmeldung ist während der
          Ordinationszeiten nötig).</p>

        <p>
          Die Praxis wird als Kassenordination geführt. Dies bedeutet, dass die Abrechnung direkt mit Ihrer
          Krankenkasse erfolgt. <b class="orange">Bitte bringen Sie daher zu jeder Untersuchung die e-card unbedingt
          mit!</b>
          Für
          Privatleistungen – dazu zählen alle Impfungen außerhalb des Gratisimpfkonzeptes (z.B.: FSME,
          Hepatitis A, Windpocken, Meningokokken), diverse Bestätigungen (z.B. vor Eintritt in Fußballverein)
          – erhalten Sie eine Honorarnote. Diese können Sie bar, mit Bankomat oder in Ausnahmefällen mittels
          Erlagschein bezahlen.
        </p>

        <div class="space-20"></div>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2660.64584876738!2d14.531739399999985!3d48.174906499999985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4773b7177ac4a3c3%3A0x21a359601c5ef731!2sHauptstra%C3%9Fe+1%2C+4300+St.+Valentin!5e0!3m2!1sde!2sat!4v1439398239812"
            width="100%" height="400px" frameborder="0" style="border:0" allowfullscreen></iframe>

        <div class="space-20"></div>
      </div>

      <div class="col-12 col-md-5">
        <div class="card bg-grey shadow">
          <div class="card-body">
            <h5 class="card-title bg-orange">Adresse <i class="bi bi-geo-alt-fill"></i></h5>
            <span>Hauptstraße 1<br>4300 St. Valentin<br>Niederösterreich</span>
          </div>
        </div>

        <div class="space-20"></div>

        <div class="card bg-grey shadow">
          <div class="card-body">
            <h5 class="card-title bg-orange">Parken <i class="bi bi-car-front-fill"></i></h5>
            <span>Bitte benützen Sie <b class="orange">nicht mehr den Parkplatz vor der Ordination</b>, sondern die zahlreichen Abstellmöglichkeiten in unmittelbarer Umgebung!! Beachten Sie bitte die Kurzparkzonen!!</span>
          </div>
        </div>

        <div class="space-20"></div>

        <div class="card bg-grey shadow">
          <div class="card-body">
            <h5 class="card-title bg-orange">Ordinationszeiten <i class="bi bi-clock-fill"></i></h5>
            <span>
              <table>
                    <colgroup>
                        <col style="width: 110px">
                    </colgroup>

                    <tr v-for="(item, index) in zeiten" v-bind:key="index">
                        <td style="vertical-align: top">{{item.tag}}</td>
                        <td>

                          <span v-for="(uhrzeit, index) in item.zeiten" v-bind:key="index">
                            {{uhrzeit}} <br v-if="item.zeiten.length - 1 > index">
                          </span>

                          <p v-if="zeiten.length - 1 > index"></p>
                        </td>
                    </tr>
                </table>
            </span>
          </div>
        </div>

        <div class="space-20"></div>


      </div>
    </div>
  </div>
</template>

<script>
import {useOrdinationszeitenStore} from "@/stores/ordinationszeiten";

export default {
  name: 'OrdinationView',

  data: () => ({

  }),

  computed:{
    zeiten: function (){
      return useOrdinationszeitenStore().getZeitenOrdination;
    }
  }
}
</script>

<style scoped>


</style>
