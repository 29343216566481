<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Allergie</h1>
        <div class="divider"><span class="bg-yellow radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <p>Die Zahl der Menschen mit Allergien hat sich in den letzten Jahren deutlich erhöht. Derzeit
          leiden knapp 20% der Bevölkerung an einer Erkrankung, die dem allergischen Formenkreis
          zuzuordnen ist. Dazu gehören die atopische Dermatitis („Neurodermitis“), die
          Nahrungsmittelallergie, die allergische Rhinoconjunctivitis (Heuschnupfen) und das (allergische)
          Asthma bronchiale. Letzteres gilt im Kindesalter überhaupt als die häufigste chronische
          Erkrankung. Die Allergie stellt in meiner Ordination einen Fachschwerpunkt dar.
        </p>
        <p>Folgende Leistungen werden angeboten</p>
        <ul>
          <li>Allergietest (Hauttest oder Blutabnahme möglich)</li>
          <li>Lungenfunktion (ab 6 Jahre möglich)</li>
          <li>Hyposensibilisierung - einzige Kausaltherapie zur Behandlung einer Allergie</li>
          <li>Notfall-Set inklusive Einschulung am Adrenalin-Pen</li>
          <li>Asthmaschulung</li>
        </ul>


      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: 'LeistungKasseAllergieView',
}
</script>

<style scoped>


</style>
