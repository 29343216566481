<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Asthmaschulung</h1>
        <div class="divider"><span class="bg-yellow radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <p>Asthma bronchiale zählt zu den häufigsten chronischen Erkrankungen im Kindesalter. Umso wichtiger
          es, mehr über diese Erkrankung, ihre Ursachen und Behandlungsmöglichkeiten zu wissen. Je nach
          Alter werden diese Informationen in 4 oder 6 Unterrichtseinheiten an Ihr Kind weitergegeben.
          Ziel ist letztendlich, dass Ihr Kind mögliche Auslöser eines Asthmaanfalls erkennt, die
          Medikamente ihrer Wirkung zuordnen und mittels eines Notfallplans frühzeitig bei Atemnot die
          nötigen Therapieschritte einleiten kann.
        </p>


        <p>Folgende Punkte sind Inhalt der Schulung</p>
        <ul>
          <li>Was ist Asthma bronchiale?</li>
          <li>Was sind mögliche Auslöser?</li>
          <li>Welche Warnzeichen kündigen einen Asthmaanfall an?</li>
          <li>Welche Medikamente benötige ich?</li>
          <li>Richtige Inhalationstechnik</li>
          <li>Sport und Asthma bronchiale</li>
          <li>Atemtherapie</li>
          <li>Notfallplan</li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <div class="card bg-grey shadow">
          <div class="card-body">
            <h5 class="card-title bg-yellow">Schulung für Kinder und Jugendliche<i class="bi bi-lungs-fill"></i></h5>

            <ul class="list">
              <li>
                <div>
                  <div style="width:60px; float:left;" class="center">
                    <div class="bg-dark radius-top padding-8">
                      <h2 class="white margin-0"><i class="bi bi-info-circle-fill"></i></h2>
                    </div>
                    <div class="bg-yellow radius-bottom padding-8 shadow">
                      <h6 class="white margin-0">INFO</h6>
                    </div>
                  </div>

                  <div class="marginleft-80" style="min-height: 90px">
                    <h4 class="dark">Dauer</h4>

                    <div class="space-5"></div>
                    <span>3 x 2 Unterrichtseinheiten zu 45 min.</span>
                  </div>
                </div>
                <div class="clear space-16"></div>
              </li>

              <li>
                <div>
                  <div style="width:60px; float:left;" class="center">
                    <div class="bg-dark radius-top padding-8">
                      <h2 class="white margin-0"><i class="bi-currency-euro"></i></h2>
                    </div>
                    <div class="bg-yellow radius-bottom padding-8 shadow">
                      <h6 class="white margin-0">75,00</h6>
                    </div>
                  </div>

                  <div class="marginleft-80" style="min-height: 90px">
                    <h4 class="dark">Kosten</h4>

                    <div class="space-5"></div>
                    <span>Die Kurskosten können bei einigen Krankenkassen eingereicht werden.</span>
                  </div>
                </div>
                <div class="clear space-16"></div>
              </li>

              <FileRenderer :files="dokumente" color="yellow" location="Asthma-Jugendliche" hide-empty-information/>
            </ul>
          </div>
        </div>

        <div class="space-20"></div>


      </div>

      <div class="col-12 col-md-6">
        <div class="card bg-grey shadow">
          <div class="card-body">
            <h5 class="card-title bg-yellow">Schulung für Kleinkinder (4-7 Jahre)<i class="bi bi-lungs-fill"></i></h5>

            <ul class="list">
              <li>
                <div>
                  <div style="width:60px; float:left;" class="center">
                    <div class="bg-dark radius-top padding-8">
                      <h2 class="white margin-0"><i class="bi bi-info-circle-fill"></i></h2>
                    </div>
                    <div class="bg-yellow radius-bottom padding-8 shadow">
                      <h6 class="white margin-0">INFO</h6>
                    </div>
                  </div>

                  <div class="marginleft-80" style="min-height: 90px">
                    <h4 class="dark">Dauer</h4>

                    <div class="space-5"></div>
                    <span>2 x 2 Unterrichtseinheiten zu 45 min.<br>1 Elternschulung</span>
                  </div>
                </div>
                <div class="clear space-16"></div>
              </li>

              <li>
                <div>
                  <div style="width:60px; float:left;" class="center">
                    <div class="bg-dark radius-top padding-8">
                      <h2 class="white margin-0"><i class="bi-currency-euro"></i></h2>
                    </div>
                    <div class="bg-yellow radius-bottom padding-8 shadow">
                      <h6 class="white margin-0">75,00</h6>
                    </div>
                  </div>

                  <div class="marginleft-80" style="min-height: 90px">
                    <h4 class="dark">Kosten</h4>

                    <div class="space-5"></div>
                    <span>Die Kurskosten können bei einigen Krankenkassen eingereicht werden.</span>
                  </div>
                </div>
                <div class="clear space-16"></div>
              </li>
              <FileRenderer :files="dokumente" color="yellow" location="Asthma-Kleinkind" hide-empty-information/>
            </ul>


          </div>
        </div>

        <div class="space-20"></div>


      </div>


    </div>
  </div>
</template>

<script>
import FileRenderer from "@/components/FileRenderer.vue";

import { useDocumentsStore } from '@/stores/documents'


export default {
  name: 'LeistungAsthmaschulungView',
  components: {FileRenderer},

  computed: {
    dokumente() {
      return useDocumentsStore().getDocuments
    }
  },

}
</script>

<style scoped>


</style>
