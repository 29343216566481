<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Impfen</h1>
        <div class="divider"><span class="bg-yellow radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>


    <div class="row">
      <div class="col-12 col-md-7">
        <p>Bis zum vollendeten 15. Lebensjahr gilt für Impfungen der im österreichischen Gratisimpfkonzept
          für Kinder enthaltenen Impfstoffe eine Sonderregelung. Da es sich um keine kurativen Leistungen
          handelt, werden sie nicht von den Krankenkassen vergütet. Dennoch sind sowohl der Impfstoff als
          auch die Impfung für Sie kostenlos.
        </p>


        <p>Die Regelung gilt für folgende Impfstoffe:</p>

        <table class="table">
          <thead>
          <tr>
            <th>Impfung</th>
            <th>Preis</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in impfstoffe" v-bind:key="index">
            <td>{{ item.impfung + (item.impfstoff !== ""?(" (" + item.impfstoff + ")") : ("")) }}</td>
            <td>{{ item.preis + " € " + (item.info !== null?(" - " + item.info) : ("")) }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="col-12 col-md-5">
        <div class="card bg-grey shadow">
          <div class="card-body">
            <h5 class="card-title bg-yellow">Informationen<i class="bi bi-info-circle-fill"></i></h5>

            <ul class="list">
              <FileRenderer :files="dokumente" color="yellow" location="Impfen-Kasse"/>
            </ul>
          </div>
        </div>

        <div class="space-20"></div>
      </div>

    </div>
  </div>
</template>

<script>
import FileRenderer from "@/components/FileRenderer.vue";

import { useDocumentsStore } from '@/stores/documents'
import {useImpfstoffeStore} from "@/stores/impfstoffe";


export default {
  name: 'LeistungKasseImpfenView',
  components: {FileRenderer},

  computed: {
    dokumente() {
      return useDocumentsStore().getDocuments
    },

    impfstoffe(){
      return useImpfstoffeStore().getImpfstoffeKasse
    },
  },
}
</script>

<style scoped>


</style>
