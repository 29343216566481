<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Impfen</h1>
        <div class="divider"><span class="bg-yellow radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>


    <div class="row">
      <div class="col-12 col-md-7">
        <p>Impfstoffe außerhalb der im österreichischen Gratisimpfkonzept für Kinder enthaltenen Impfungen
          müssen selbst bezahlt werden. Zusätzlich fällt nun auch eine Impfgebühr an. Die Höhe der Gebühr
          wird durch die österreichische Ärztekammer geregelt.
        </p>


        <p>Die Regelung gilt für folgende Impfstoffe:</p>

        <table class="table">
          <thead>
          <tr>
            <th>Impfung</th>
            <th>Preis</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in impfstoffe" v-bind:key="index">
            <td>{{ item.impfung + (item.impfstoff !== "" ? (" (" + item.impfstoff + ")") : ("")) }}</td>
            <td>{{ item.preis + " € " + (item.info !== null ? (" - " + item.info) : ("")) }}</td>
          </tr>
          </tbody>
        </table>

        <p>Die Impfstoffe werden auf Wunsch von uns besorgt und sind am Impftermin in der Ordination vorrätig. Bitte
          beachten Sie, dass bestellte Impfstoffe auf jeden Fall in Rechnung gestellt werden!</p>
      </div>

      <div class="col-12 col-md-5">
        <div class="card bg-grey shadow">
          <div class="card-body">
            <h5 class="card-title bg-yellow">Informationen<i class="bi bi-info-circle-fill"></i></h5>

            <ul class="list">
              <li>
                <div>
                  <div style="width:60px; float:left;" class="center">
                    <div class="bg-dark radius-top padding-8">
                      <h2 class="white margin-0"><i class="bi-currency-euro"></i></h2>
                    </div>
                    <div class="bg-yellow radius-bottom padding-8 shadow">
                      <h6 class="white margin-0">15,00</h6>
                    </div>
                  </div>

                  <div class="marginleft-80" style="min-height: 90px">
                    <h4 class="dark">Kosten</h4>

                    <div class="space-5"></div>
                    <span>Impfgebühr</span>
                  </div>
                </div>
                <div class="clear space-16"></div>
              </li>

              <FileRenderer :files="dokumente" color="yellow" location="Impfen-Privat" hide-empty-information/>

            </ul>
          </div>
        </div>

        <div class="space-20"></div>
      </div>

    </div>
  </div>
</template>

<script>
import FileRenderer from "@/components/FileRenderer.vue";

import {useDocumentsStore} from '@/stores/documents'
import {useImpfstoffeStore} from "@/stores/impfstoffe";


export default {
  name: 'LeistungPrivatImpfenView',
  components: {FileRenderer},

  computed: {
    dokumente() {
      return useDocumentsStore().getDocuments
    },

    impfstoffe(){
      return useImpfstoffeStore().getImpfstoffePrivat
    },
  },
}
</script>

<style scoped>


</style>
