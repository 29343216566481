<template>
  <div class="container">
    <div class="row">
      <div class="col-12 mb-2">
        <h4>Ordinationszeitenverwaltung</h4>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <table class="table table-hover " style="width: 100%">
          <thead>
          <tr class="table-dark">
            <th scope="col" >Tag</th>
            <th scope="col" >Uhrzeit</th>
            <th scope="col" style="width: 110px;"></th>
          </tr>
          </thead>
          <tbody class="">

          <tr v-for="item in ordinationszeiten" v-bind:key="item.id">
            <td class="align-middle">{{ item.tag }}</td>
            <td class="align-middle">{{ item.zeit }}</td>
            <td>
              <button @click="openEditEntryDialog(item.id)" type="button" class="btn btn-light me-2"><i
                  class="bi yellow bi-pen-fill"></i></button>
              <button @click="deleteEntry(item.id)" type="button" class="btn btn-light"><i
                  class="bi yellow bi-trash-fill"></i></button>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="d-flex flex-row-reverse">
          <button type="button" class="btn btn-light" @click="openAddEntryDialog()"><i
              class="bi yellow bi-plus-circle-fill"></i></button>

          <button type="button" class="btn btn-light me-1" @click="refresh()"><i
              class="bi yellow bi-arrow-clockwise"></i></button>
        </div>

      </div>
    </div>


    <div class="modal" tabindex="-1" id="editElementModalOrdinationszeitenManager">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Ordinationszeiteneintrag bearbeiten</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-2 row">
              <label for="ordinationszeittag" class="col-sm-3 col-form-label">Tag</label>
              <div class="col-sm-9">
                <select class="form-select" id="ordinationszeittag">
                  <option value="Montag">Montag</option>
                  <option value="Dienstag">Dienstag</option>
                  <option value="Mittwoch">Mittwoch</option>
                  <option value="Donnerstag">Donnerstag</option>
                  <option value="Freitag">Freitag</option>
                  <option value="Samstag">Samstag</option>
                  <option value="Sonntag">Sonntag</option>
                </select>

                <div class="invalid-feedback">
                  Angaben sind erforderlich
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="ordinationszeitzeiten" class="col-sm-3 col-form-label">Uhrzeit</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" id="ordinationszeitzeiten" value="">
                <div class="invalid-feedback">
                  Angaben sind erforderlich
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a class="button shadow medium bg-greydark" data-bs-dismiss="modal">Close</a>
            <a type="submit" class="button shadow medium bg-yellow" @click="validateEditEntryDialog()">Speichern</a>
            <input type="hidden" id="ordinationszeitenid"/>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>


<script>

import {useOrdinationszeitenStore} from "@/stores/ordinationszeiten";

export default {
  name: 'OrdinationszeitenManager',

  computed: {
    ordinationszeiten() {
      return useOrdinationszeitenStore().getZeiten
    },
  },

  methods: {
    editEntry: function (id, tag, zeiten) {
      useOrdinationszeitenStore().edit(id, tag, zeiten);
    },

    refresh: function(){
      useOrdinationszeitenStore().fetch();
    },

    addEntry: async function (tag, zeiten) {
      useOrdinationszeitenStore().create(tag, zeiten);
    },

    deleteEntry: function (id) {
      useOrdinationszeitenStore().delete(id);
    },

    openAddEntryDialog: function () {
      var modalDOM = document.getElementById("editElementModalOrdinationszeitenManager");
      var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);

      modalDOM.querySelector('.modal-title').innerHTML = "Ordinationszeiteneintrag erstellen";
      modalDOM.querySelector("#ordinationszeittag").value = "Montag";
      modalDOM.querySelector("#ordinationszeitzeiten").value = "";
      modalDOM.querySelector("#ordinationszeitenid").value = -1;

      this.clearValidationEditEntryDialog();
      modal.show();
    },

    openEditEntryDialog: function (id) {
      var eintrag = this.ordinationszeiten.find(item => item.id === id);
      var modalDOM = document.getElementById("editElementModalOrdinationszeitenManager");
      var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);

      modalDOM.querySelector('.modal-title').innerHTML = "Ordinationszeiteneintrag bearbeiten";
      modalDOM.querySelector("#ordinationszeittag").value = eintrag.tag;
      modalDOM.querySelector("#ordinationszeitzeiten").value = eintrag.zeit;
      modalDOM.querySelector("#ordinationszeitenid").value = eintrag.id;


      this.clearValidationEditEntryDialog();
      modal.show();
    },

    validateEditEntryDialog: function () {
      this.clearValidationEditEntryDialog();

      var modalDOM = document.getElementById("editElementModalOrdinationszeitenManager");
      var tag = modalDOM.querySelector("#ordinationszeittag").value;
      var zeit = modalDOM.querySelector("#ordinationszeitzeiten").value;
      var id = modalDOM.querySelector("#ordinationszeitenid").value;

      var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);


      var valid = true;


      if(tag.length > 0){
        modalDOM.querySelector("#ordinationszeittag").classList.add("is-valid");
      }else{
        modalDOM.querySelector("#ordinationszeittag").classList.add("is-invalid");
        valid = false;
      }

      if(zeit.length > 0) {
        modalDOM.querySelector("#ordinationszeitzeiten").classList.add("is-valid");
      }else{
        modalDOM.querySelector("#ordinationszeitzeiten").classList.add("is-invalid");
        valid = false;
      }

      if(valid){
        if(Number(id) !== -1){
          //EDIT MODE
          this.editEntry(id, tag, zeit);
          modal.hide();
        }else{
          //ADD MODE
          this.addEntry(tag, zeit);
          modal.hide();
        }
      }
    },

    clearValidationEditEntryDialog: function () {
      var modalDOM = document.getElementById("editElementModalOrdinationszeitenManager");

      modalDOM.querySelector("#ordinationszeitzeiten").classList.remove("is-valid");
      modalDOM.querySelector("#ordinationszeitzeiten").classList.remove("is-invalid");

      modalDOM.querySelector("#ordinationszeittag").classList.remove("is-valid");
      modalDOM.querySelector("#ordinationszeittag").classList.remove("is-invalid");
    },
  }
}
</script>

<style scoped>

</style>