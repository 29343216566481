import axiosLib from "axios";

const api = axiosLib.create({
    baseURL: "https://api.kinderarzt-kusolitsch.at/api/",
    timeout: 60000,
    withCredentials: true,
    withXSRFToken: true,
    headers: {
        Accept: "application/json"
    },

});


export default api;