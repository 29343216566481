<template>
  <div class="container">
    <div class="row">
      <div class="col-12 mb-2">
        <h4>Terminvergabe</h4>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <table class="table table-hover " style="width: 100%">
          <thead>
          <tr class="table-dark">
            <th scope="col" style="width: 150px;">Status</th>
            <th scope="col">Vorname</th>
            <th scope="col">Nachname</th>
            <th scope="col">Geburtsdatum</th>
            <th scope="col">Termin</th>
            <th scope="col" style="width: 110px;"></th>
          </tr>
          </thead>
          <tbody class="">

          <tr v-for="item in termine" v-bind:key="item.id">
            <td class="align-middle">
              <div v-if="item.status === 'Anfrage'" class="bi bi-info-circle-fill blue"> Anfrage</div>
              <div v-if="item.status === 'Bearbeitet'" class="bi bi-question-circle-fill yellow"> Bearbeitet</div>
              <div v-if="item.status === 'Angenommen'" class="bi bi-check-circle-fill green"> Angenommen</div>
              <div v-if="item.status === 'Abgelehnt'" class="bi bi-x-circle-fill red"> Abgelehnt</div>
            </td>
            <td  class="align-middle">{{ item.vorname }}</td>
            <td  class="align-middle">{{ item.nachname }}</td>
            <td  class="align-middle">{{ new Date(item.geburtsdatum).toLocaleDateString() }}</td>
            <td  class="align-middle">{{ formateDateTime(item.termin_response) }}</td>
            <td>
              <button v-if="item.status === 'Anfrage'" @click="openEditEntryDialog(item.id)" type="button" class="btn btn-light me-2"><i
                  class="bi green bi-pen-fill"></i></button>

              <button v-if="item.status !== 'Anfrage'" @click="openEditEntryDialog(item.id)" type="button" class="btn btn-light me-2"><i
                  class="bi green bi-eye-fill"></i></button>

              <button @click="deleteEntry(item.id)" type="button" class="btn btn-light"><i
                  class="bi green bi-trash-fill"></i></button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>



    <div class="modal" tabindex="-1" id="editTerminanfrageView">
      <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Terminanfrage vom {{ new Date(selectedTermin.created_at).toLocaleDateString() }} bearbeiten</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-2 row">
              <label class="col-sm-3 col-form-label">Vorname</label>
              <div class="col-sm-9">
                <span class="form-control" style="border:none">{{ selectedTermin.vorname }}</span>
              </div>
            </div>

            <div class="mb-2 row">
              <label class="col-sm-3 col-form-label">Nachname</label>
              <div class="col-sm-9">
                <span class="form-control" style="border:none">{{ selectedTermin.nachname }}</span>
              </div>
            </div>

            <div class="mb-2 row">
              <label class="col-sm-3 col-form-label">Geburtsdatum</label>
              <div class="col-sm-9">
                <span class="form-control" style="border:none">{{ new Date(selectedTermin.geburtsdatum).toLocaleDateString()}}</span>
              </div>
            </div>

            <div class="mb-2 row">
              <label class="col-sm-3 col-form-label">Alter</label>
              <div class="col-sm-9">
                <span class="form-control" style="border:none">{{ this.getAge(this.selectedTermin.geburtsdatum) }}</span>
              </div>
            </div>
            <hr>

            <div class="mb-2 row">
              <label class="col-sm-3 col-form-label">E-Mail</label>
              <div class="col-sm-9">
                <span class="form-control" style="border:none">{{ selectedTermin.email }}</span>
              </div>
            </div>

            <div class="mb-2 row">
              <label class="col-sm-3 col-form-label">Telefonnummer</label>
              <div class="col-sm-9">
                <span class="form-control" style="border:none">{{ formatPhoneNumber(selectedTermin.telefonnummer) }}</span>
              </div>
            </div>

            <hr>

            <div class="mb-2 row">
              <label class="col-sm-3 col-form-label">Termin</label>
              <div class="col-sm-9">
                <span class="form-control" style="border:none">{{ new Date(selectedTermin.termin1_request).toLocaleDateString() + " oder " + new Date(selectedTermin.termin2_request).toLocaleDateString() }}</span>
              </div>
            </div>

            <hr>

            <div class="mb-2 row">
              <label class="col-sm-3 col-form-label">Untersuchungen</label>
              <div class="col-sm-9">
                <div class="form-control" style="border:none">
                  <span class="badge bg-green m-1 p-2" v-if="selectedTermin.untersuchung_mutterkindpass">Mutter-Kind-Pass</span>
                  <span class="badge bg-green m-1 p-2" v-if="selectedTermin.untersuchung_ultraschallniere">Ultraschall Niere</span>
                  <span class="badge bg-green m-1 p-2" v-if="selectedTermin.untersuchung_ultraschallhuefte">Ultraschall Hüfte</span>
                  <span class="badge bg-green m-1 p-2" v-if="selectedTermin.untersuchung_allergieabklaerung">Allergieabklärung</span>
                  <span class="badge bg-green m-1 p-2" v-if="selectedTermin.untersuchung_lungenfunktion">Lungenfunktion</span>
                  <span class="badge bg-green m-1 p-2" v-if="selectedTermin.untersuchung_abklaerungvoneinnaessen">Abklärung von Einnässen</span>
                  <span class="badge bg-green m-1 p-2" v-if="selectedTermin.untersuchung_impfung">Impfung</span>
                  <span class="badge bg-green m-1 p-2" v-if="selectedTermin.untersuchung_sonstiges">Sonstiges</span>
                </div>
              </div>
            </div>

            <div class="mb-2 row" v-if="selectedTermin.untersuchung_impfung">
              <label class="col-sm-3 col-form-label">Impfungen</label>
              <div class="col-sm-9">
                <div class="form-control" style="border:none">
                  <span class="badge bg-green m-1 p-2" v-for="(item, index) in selectedTermin.untersuchung_impfung_impfstoffe" v-bind:key="index">{{ item }}</span>
                </div>
              </div>
            </div>
            <hr>

            <div v-if="(selectedTermin.anmerkung_request) !== ''">
              <div class="mb-2 row">
                <label class="col-sm-3 col-form-label">Anmerkung</label>
                <div class="col-sm-9">
                  <div class="form-control" style="border:none">
                    <span>
                      {{selectedTermin.anmerkung_request}}
                    </span>
                  </div>
                </div>
              </div>
              <hr>
            </div>



            <div class="mb-2 row">
              <label for="termindatetime" class="col-sm-3 col-form-label">Termin</label>
              <div class="col-sm-9">
                <input type="datetime-local" class="form-control" id="termindatetime" value="" :disabled="selectedTermin.status!=='Anfrage'" v-model="terminDateTime">
                <div class="invalid-feedback" id="termindatetime-feedback">
                  Ungültiges Datum
                </div>
              </div>
            </div>

            <div class="mb-2 row">
              <label for="termintext" class="col-sm-3 col-form-label" >Anmerkung</label>
              <div class="col-sm-9">
                <textarea class="form-control" id="termintext" rows="3" :disabled="selectedTermin.status!=='Anfrage'" v-model="terminAnmerkung"></textarea>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <a class="button shadow medium bg-greydark" data-bs-dismiss="modal">Close</a>
            <a v-if="selectedTermin.status === 'Anfrage'" type="submit" class="button shadow medium bg-green" @click="validateModal()">Speichern</a>
            <input type="hidden" id="userid"/>
          </div>
        </div>
      </div>
    </div>


    <div class="d-flex flex-row-reverse">
      <button type="button" class="btn btn-light me-1" @click="refresh()"><i
          class="bi green bi-arrow-clockwise"></i></button>
    </div>

  </div>
</template>



<script>
import {useAdminStore} from "@/stores/admin";
import {useTermineStore} from "@/stores/termine";
import {useOrdinationszeitenStore} from "@/stores/ordinationszeiten";

export default {
  name: 'TerminvergabeAnfragen',
  data: () => ({
    selectedTermin: {},

    terminDateTime: "",
    terminAnmerkung: "",
  }),

  computed: {
    termine(){
      return useAdminStore().getTermine;
    }
  },


  methods: {
    deleteEntry: function (id) {
      useAdminStore().deleteTermin(id);
    },


    refresh: function(){
      useAdminStore().fetchTermine();
    },

    formatPhoneNumber: function(phoneNumberString) {
      return phoneNumberString;
    },

    getAge: function(DOB) {
      var today = new Date("2024-07-01");
      var birthDate = new Date(DOB);

      var difference_year = today.getFullYear() - birthDate.getFullYear();
      var difference_month = today.getMonth() - birthDate.getMonth();
      var difference_day = today.getDate() - birthDate.getDate();

      if(difference_day < 0){
        difference_month--;
        difference_day += new Date(today.getFullYear(), today.getMonth()+1, 0).getDate();
      }

      if(difference_month < 0){
        difference_month += 12;
        difference_year--;
      }

      return difference_year + " Jahre, " + difference_month + " Monate und " + difference_day + " Tage";
    },

    openEditEntryDialog: function (id) {
      this.clearValidation();
      this.selectedTermin  = this.termine.find(item => item.id === id);

      if(this.selectedTermin.status !== "Anfrage"){
        this.terminDateTime = this.selectedTermin.termin_response;
        this.terminAnmerkung = this.selectedTermin.anmerkung_response;
      }else{
        this.terminDateTime = "";
        this.terminAnmerkung = "";
      }



      var modalDOM = document.getElementById("editTerminanfrageView");
      var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);
      modal.show();
    },


    clearValidation(){
      var modalDOM = document.getElementById("editTerminanfrageView");

      modalDOM.querySelector("#termindatetime").classList.remove("is-valid");
      modalDOM.querySelector("#termindatetime").classList.remove("is-invalid");

      modalDOM.querySelector("#termintext").classList.remove("is-valid");
      modalDOM.querySelector("#termintext").classList.remove("is-invalid");
    },

    validateModal(){
      var modalDOM = document.getElementById("editTerminanfrageView");
      var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);

      this.clearValidation();
      var valid = true;

      document.querySelector("#termintext").classList.add("is-valid");


      if (!isNaN(new Date(this.terminDateTime)) && (new Date(this.terminDateTime) > new Date())) {

        if(this.validateDateUrlaub(this.terminDateTime, "#termindatetime-feedback") && this.validateOrdinationsZeiten(this.terminDateTime, "#termindatetime-feedback")){
          document.querySelector("#termindatetime").classList.add("is-valid");
        }else{
          document.querySelector("#termindatetime").classList.add("is-invalid");
          valid = false;
        }

      } else {
        document.querySelector("#termindatetime").classList.add("is-invalid");
        document.querySelector("#termindatetime-feedback").innerHTML = "Das Datum kann nicht in der Vergangenheit liegen";

        valid = false;
      }

      /*const formatter = new Intl.DateTimeFormat('de-AT', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' });
      console.log(formatter.format(this.terminDateTime))*/

      const d = new Date(this.terminDateTime);
      const formattedDate = [d.getFullYear(), (d.getMonth() + 1).toString().padStart(2, '0'), d.getDate().toString().padStart(2, '0')].join('-') + ' ' + [d.getHours().toString().padStart(2, '0'), d.getMinutes().toString().padStart(2, '0'), "00"].join(':');

      if(valid){
        if(useAdminStore().editTermin(this.selectedTermin.id, formattedDate, this.terminAnmerkung)){
          modal.hide();
        }
      }
    },

    formateDateTime(value){
      if(value === null)
        return "";
      else{
        const d = new Date(value);
        return [d.getDate().toString().padStart(2, '0'), (d.getMonth() + 1).toString().padStart(2, '0'), d.getFullYear()].join('.') + ' ' + [d.getHours().toString().padStart(2, '0'), d.getMinutes().toString().padStart(2, '0')].join(':') + " Uhr";
      }
    },


    validateDateUrlaub(datum, selector){
      var urlaube = useTermineStore().getTermine.filter(item => item.typ === "Urlaub");
      var kollisionen = urlaube.filter(item => new Date(item.startdatum) <= new Date(datum) && new Date(datum) <= new Date(item.enddatum));

      if(kollisionen.length === 0){
        return true;
      }
      else{
        document.querySelector(selector).innerHTML = "Das Datum liegt in unserem Urlaub (" + new Date(kollisionen[0].startdatum).toLocaleDateString() + " - " + new Date(kollisionen[0].enddatum).toLocaleDateString() + "). Bitte wählen Sie einen anderen Tag.";
        return false;
      }
    },

    validateOrdinationsZeiten(datum, selector){
      const wochentag = ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag' ];
      var zeiten = useOrdinationszeitenStore().getZeiten;
      var ordinationszeiten = useOrdinationszeitenStore().getZeitenOrdination.map(item => item.tag).join(", ");
      var kollisionen = zeiten.filter(item => item.tag === wochentag[new Date(datum).getDay()]);

      if(kollisionen.length !== 0){
        return true;
      }
      else{
        var announcements = useTermineStore().getTermine.filter(item => item.typ === "Ankündigung");
        var collisions = announcements.filter(item => new Date(item.startdatum) <= new Date(datum) && new Date(datum) <= new Date(item.enddatum));

        if(collisions.length !== 0){
          return true;
        }else{
          document.querySelector(selector).innerHTML = "Das Datum liegt außerhalb unserer Ordinationszeiten (" + ordinationszeiten + "). Bitte wählen Sie einen anderen Tag.";
          return false;
        }
      }
    },
  }
}
</script>

<style scoped>

</style>