<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Blutabnahme</h1>
        <div class="divider"><span class="bg-yellow radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <p>Aufgrund des Krankheitsverlaufs ist es gelegentlich notwendig, Laborbefunde zu bestimmen. Vor
          allem als Entscheidungshilfe, ob eine virale oder eine bakterielle Erkrankung vorliegt (und
          damit eine antibiotische Medikation nötig ist), ist die Bestimmung eines Blutbildes und des
          CRP-Wertes (Akutentzündungswert) oft sehr hilfreich. Dies erfolgt mittels kapillärer Blutabnahme
          aus der Fingerkuppe – das Ergebnis liegt nach ungefähr 5 Minuten vor. Darüber hinaus gehende
          Befunde (auch Blutwerte im Rahmen der OP-vorbereitung) werden aus der Vene abgenommen und an das
          1. NÖ Laborinstitut in St. Pölten gesendet. In einer Befundbesprechung einige Tage später werden
          die Ergebnisse und eventuell weitere Untersuchungsschritte erörtert.
        </p>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: 'LeistungKasseBlutabnahmeView',
}
</script>

<style scoped>


</style>
