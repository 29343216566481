<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Terminvergabe</h1>
        <div class="divider"><span class="bg-green radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <p>Neben der telefonischen Terminvergabe kann ein Untersuchungstermin auch per E-Mail vereinbart
          werden. Füllen Sie bitte dazu alle Felder aus. Ihre Anfrage wird - je nach Patientenaufkommen in
          der Ordination - möglichst rasch bearbeitet. <b class="green">Termine für Akutereignisse (z.B. Krankheit)
            können ausschließlich telefonisch vereinbart werden!</b></p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2>Patientendaten</h2>
      </div>

      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="form-label" for="vornamekind">Vorname des Kindes</label>
          <input type="text" class="form-control" id="vornamekind" name="vorname"
                 placeholder="Vorname des Kindes" required v-model="vorname">
          <div class="invalid-feedback">
            erforderlich
          </div>
        </div>
        <div class="space-20"></div>
      </div>

      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="form-label" for="nachnamekind">Nachname des Kindes</label>
          <input type="text" class="form-control" id="nachnamekind" name="nachname"
                 placeholder="Nachname des Kindes" required v-model="nachname">
          <div class="invalid-feedback">
            erforderlich
          </div>
        </div>
        <div class="space-20"></div>
      </div>

      <div class="col-xs-12 col-md-6">
        <div class="form-group">
          <label class="form-label" for="geburtstagkind">Geburtsdatum des Kindes</label>
          <input type="date" class="form-control" id="geburtstagkind" name="geburtsdatum"
                 placeholder="Geburtsdatum (tt.mm.jjjj)" required :max="new Date().toISOString().split('T')[0]"
                 v-model="geburtsdatum">
          <div class="invalid-feedback">
            Das Datum kann nicht in der Zukunft liegen
          </div>
        </div>
        <div class="space-20"></div>
      </div>
    </div>

    <div class="space-20"></div>


    <div class="row">
      <div class="col-12">
        <h2>Kontaktinformationen</h2>
      </div>

      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="form-label" for="emailadresse">E-Mail Adresse</label>
          <input type="email" class="form-control" id="emailadresse" name="email"
                 placeholder="E-Mail Adresse" required v-model="email">
          <div class="invalid-feedback">
            Gültige E-Mail Adresse erforderlich
          </div>
        </div>
        <div class="space-20"></div>
      </div>

      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="form-label" for="telefonnummer">Telefonnummer</label>
          <input type="tel" class="form-control" id="telefonnummer" name="telefon"
                 placeholder="Telefonnummer" required v-model="telefon">
          <div class="invalid-feedback">
            Gültige Telefonnummer erforderlich
          </div>
        </div>
        <div class="space-20"></div>
      </div>
    </div>

    <div class="space-20"></div>

    <div class="row">
      <div class="col-12">
        <h2>Untersuchungstermin</h2>
      </div>

      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="form-label" for="untersuchungstermin">Untersuchungstermin Datum</label>
          <input type="date" class="form-control" id="untersuchungstermin" name="untersuchungstermin"
                 placeholder="Untersuchungstermin" :min="new Date().toISOString().split('T')[0]" v-model="termin1">
          <div class="invalid-feedback" id="invalid-feedback-custom-termin1">
            Das Datum kann nicht in der Vergangenheit liegen
          </div>
        </div>
        <div class="space-20"></div>
      </div>


      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="form-label" for="ersatztermin">Ersatztermin Datum</label>
          <input type="date" class="form-control" id="ersatztermin" name="ersatztermin"
                 placeholder="Ersatztermin" :min="new Date().toISOString().split('T')[0]" v-model="termin2">
          <div class="invalid-feedback" id="invalid-feedback-custom-termin2">
            Das Datum kann nicht in der Vergangenheit liegen
          </div>
        </div>
        <div class="space-20"></div>
      </div>


    </div>

    <div class="space-20"></div>


    <div class="row">
      <div class="col-12">
        <h2>Untersuchung</h2>
      </div>
    </div>


    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="input-group  mb-2">
          <div class="input-group-text form-control" style="width: 100%">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="mutterkindpass"
                     name="mutterkindpass" v-model="untersuchungen.mutterkindpass">
              <label class="form-check-label" for="mutterkindpass">
                Mutter-Kind-Pass-Untersuchung
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="input-group  mb-2">
          <div class="input-group-text form-control" style="width: 100%">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="ultraschallniere"
                     name="ultraschallniere" v-model="untersuchungen.ultraschallniere">
              <label class="form-check-label" for="ultraschallniere">
                Ultraschall Niere
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="input-group  mb-2">
          <div class="input-group-text form-control" style="width: 100%">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="ultraschallhuefte"
                     name="ultraschallhuefte" v-model="untersuchungen.ultraschallhuefte">
              <label class="form-check-label" for="ultraschallhuefte">
                Ultraschall Hüfte
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="input-group  mb-2">
          <div class="input-group-text form-control" style="width: 100%">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="allergieabklaerung"
                     name="allergieabklaerung" v-model="untersuchungen.allergieabklaerung">
              <label class="form-check-label" for="allergieabklaerung">
                Allergieabklärung
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="input-group  mb-2">
          <div class="input-group-text form-control" style="width: 100%">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="lungenfunktion"
                     name="lungenfunktion" v-model="untersuchungen.lungenfunktion">
              <label class="form-check-label" for="lungenfunktion">
                Lungenfunktion
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">

        <div class="input-group  mb-2">
          <div class="input-group-text form-control" style="width: 100%">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="abklaerungeinaessen"
                     name="abklaerungeinaessen" v-model="untersuchungen.abklaerungeinaessen">
              <label class="form-check-label" for="abklaerungeinaessen">
                Abklärung von Einnässen
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="input-group mb-2 ">
          <div class="input-group-text form-control">
            <div class="form-check has-validation">
              <input class="form-check-input" type="checkbox" v-model="untersuchungen.impfung" id="impfung"
                     name="impfung">

              <label class="form-check-label" for="impfung">
                Impfung
              </label>
            </div>
          </div>

          <button class="btn btn-outline-secondary dropdown-toggle" type="button" :disabled="!untersuchungen.impfung"
                  data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">Impfstoffauswahl
            ({{ this.untersuchungen.impfung?this.selectedImpfstoffe.length: 0 }})
          </button>
          <ul class="dropdown-menu">
            <li v-for="(impfstoff, index) in impfstoffe" v-bind:key="index">
              <div class="input-group-text" style="width: 100%; background: none !important; border:none;">
                <div class="form-check">
                  <input class="form-check-input impfstoffselect" type="checkbox" value=""
                         :id="'impstoff_' + impfstoff.id" :name="'impstoff_' + impfstoff.id"
                         @click="updateImpfstoff(impfstoff.id, $event)">
                  <label class="form-check-label" :for="'impstoff_' + impfstoff.id">{{ impfstoff.impfung }}</label>
                </div>
              </div>
            </li>

            <li>
              <div class="input-group-text" style="width: 100%; background: none !important; border:none;">
                <div class="form-check">
                  <input class="form-check-input impfstoffselect" type="checkbox" value="" id="impfstoff_sonstige"
                         name="impfstoff_sonstige" @click="updateImpfstoff(-1, $event)">
                  <label class="form-check-label" for="impfstoff_sonstige">Sonstige (Bitte im Textfeld anführen)</label>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="invalid-feedback" id="invalid-custom-impfung">
          Es muss mindestens ein Impfstoff ausgewählt werden
        </div>

      </div>
      <div class="col-12 col-md-6 col-lg-4">

        <div class="input-group  mb-2">
          <div class="input-group-text form-control" style="width: 100%">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="sonstiges" name="sonstiges"
                     v-model="untersuchungen.sonstiges">

              <label class="form-check-label" for="sonstiges">
                Sonstiges (Bitte im Textfeld anführen)
              </label>

            </div>
          </div>
        </div>

        <div class="invalid-feedback" id="invalid-custom-sonstiges">
          Bitte wählen Sie mindestens eine Untersuchung
        </div>

      </div>
    </div>

    <div class="space-20"></div>

    <div class="row">
      <div class="col-12">
        <p>
          Falls Sie den Impfstoff selbst besorgen möchten, bitte <b class="green">UNBEDINGT im Textfeld vermerken!</b>

        </p>
        <p>Sie erhalten ein E-Mail mit dem Termin. Sollten sie den Termin wahrnehmen wollen, bestätigen Sie diesen bitte
          durch Drücken des Buttons <b class="green">"Termin bestätigen"</b>.
          Andernfalls drücken Sie bitte <b class="green">"Termin ablehnen"</b>, sodass der reservierte Termin wieder
          vergeben werden kann.
          Bitte stornieren Sie einen vereinbarten Termin rechtzeitig, wenn Sie diesen nicht wahrnehmen können!
        </p>
      </div>
    </div>

    <div class="space-20"></div>

    <div class="row">
      <div class="col-12">
        <h2>Anmerkungen</h2>
      </div>

      <div class="col-12">
        <div class="form-group">
          <label for="comment">Anmerkung:</label>
          <textarea class="form-control" rows="5" name="anmerkung" id="comment" v-model="anmerkung"></textarea>
          <div class="invalid-feedback">
            Für Sonstige Untersuchungen/Impfstoffe sind weitere Angaben erforderlich
          </div>
        </div>
      </div>

      <div class="space-20"></div>


      <div class="col-12">
        <a class="button bg-green  medium shadow" @click="terminAnfragen">Termin anfragen</a>
      </div>
    </div>


    <div class="space-20"></div>


    <div class="modal" tabindex="-1" id="terminviewmodal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Terminvergabe</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Ihre Terminanfrage wurde übermittelt.</p>


            <p>Sie erhalten ein E-Mail mit dem Termin. Sollten sie den Termin wahrnehmen wollen, bestätigen Sie diesen bitte
            durch Drücken des Buttons <b class="green">"Termin bestätigen"</b> in der E-Mail. Andernfalls drücken Sie bitte <b class="green">"Termin ablehnen"</b>, sodass der reservierte Termin wieder
            vergeben werden kann.</p>

            <p>Bitte stornieren Sie einen vereinbarten Termin rechtzeitig, wenn Sie diesen nicht wahrnehmen können!</p>

          </div>
          <div class="modal-footer">
            <a class="button shadow medium bg-green" data-bs-dismiss="modal">OK</a>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import {useImpfstoffeStore} from "@/stores/impfstoffe";
import {useTermineStore} from "@/stores/termine";
import {useOrdinationszeitenStore} from "@/stores/ordinationszeiten";
import api from "@/axios";


export default {
  name: 'TermineView',
  data: () => ({
    selectedImpfstoffe: [],
    untersuchungen: {
      mutterkindpass: false,
      ultraschallniere: false,
      ultraschallhuefte: false,
      allergieabklaerung: false,
      lungenfunktion: false,
      abklaerungvoneinnaessen: false,
      impfung: false,
      sonstiges: false,
    },
    vorname: "",
    nachname: "",
    geburtsdatum: "",
    email: "",
    telefon: "",
    termin1: "",
    termin2: "",
    anmerkung: "",
  }),

  computed: {
    impfstoffe: function () {
      var impfstoffe = useImpfstoffeStore().getImpfstoffe

      const unique = impfstoffe.filter(
          (obj, index) =>
              impfstoffe.findIndex((item) => item.impfung === obj.impfung) === index
      );
      return unique.sort((a, b) => a.impfung.localeCompare(b.impfung));
    },

  },

  methods: {
    updateImpfstoff(id, event) {
      if (event.target.checked) {
        this.selectedImpfstoffe.push(id);
      } else {
        this.selectedImpfstoffe = this.selectedImpfstoffe.filter(item => item !== id);
      }
    },


    async terminAnfragen() {
      this.clearValidation();
      var result = this.validate();


      if (result) {

        const that = this;

        try {
          const res = await api.put("/terminanfragen", {
            vorname: that.vorname,
            nachname: that.nachname,
            geburtsdatum: that.geburtsdatum,
            email: that.email,
            telefonnummer: that.telefon,
            termin1: that.termin1,
            termin2: that.termin2,
            anmerkung: that.anmerkung,

            untersuchung_mutterkindpass: that.untersuchungen.mutterkindpass,
            untersuchung_ultraschallniere: that.untersuchungen.ultraschallniere,
            untersuchung_ultraschallhuefte: that.untersuchungen.ultraschallhuefte,
            untersuchung_allergieabklaerung: that.untersuchungen.allergieabklaerung,
            untersuchung_lungenfunktion: that.untersuchungen.lungenfunktion,
            untersuchung_abklaerungvoneinnaessen: that.untersuchungen.abklaerungvoneinnaessen,
            untersuchung_impfung: that.untersuchungen.impfung,
            untersuchung_sonstiges: that.untersuchungen.sonstiges,

            untersuchung_impfung_impfstoffe: that.untersuchungen.impfung ? that.selectedImpfstoffe : null,
          });

          if (res.status === 204) {
            var modalDOM = document.getElementById("terminviewmodal");
            var modal = window.bootstrap.Modal.getOrCreateInstance(modalDOM);
            modal.show();
          }
        } catch (e) {
          e;
        }
      }
    },

    validateDateUrlaub(datum, selector){
        var urlaube = useTermineStore().getTermine.filter(item => item.typ === "Urlaub");
        var kollisionen = urlaube.filter(item => new Date(item.startdatum) <= new Date(datum) && new Date(datum) <= new Date(item.enddatum));

        if(kollisionen.length === 0){
          return true;
        }
        else{
          document.querySelector(selector).innerHTML = "Das Datum liegt in unserem Urlaub (" + new Date(kollisionen[0].startdatum).toLocaleDateString() + " - " + new Date(kollisionen[0].enddatum).toLocaleDateString() + "). Bitte wählen Sie einen anderen Tag.";
          return false;
        }
    },

    validateOrdinationsZeiten(datum, selector){
      const wochentag = ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag' ];
      var zeiten = useOrdinationszeitenStore().getZeiten;
      var ordinationszeiten = useOrdinationszeitenStore().getZeitenOrdination.map(item => item.tag).join(", ");
      var kollisionen = zeiten.filter(item => item.tag === wochentag[new Date(datum).getDay()]);

      if(kollisionen.length !== 0){
        return true;
      }
      else{
        var announcements = useTermineStore().getTermine.filter(item => item.typ === "Ankündigung");
        var collisions = announcements.filter(item => new Date(item.startdatum) <= new Date(datum) && new Date(datum) <= new Date(item.enddatum));

        if(collisions.length !== 0){
          return true;
        }else{
          document.querySelector(selector).innerHTML = "Das Datum liegt außerhalb unserer Ordinationszeiten (" + ordinationszeiten + "). Bitte wählen Sie einen anderen Tag.";
          return false;
        }
      }
    },

    validate() {
      var valid = true;

      if (this.vorname.length > 0) {
        document.querySelector("#vornamekind").classList.add("is-valid");
      } else {
        document.querySelector("#vornamekind").classList.add("is-invalid");
        valid = false;
      }

      if (this.nachname.length > 0) {
        document.querySelector("#nachnamekind").classList.add("is-valid");
      } else {
        document.querySelector("#nachnamekind").classList.add("is-invalid");
        valid = false;
      }

      if (this.email.length > 0 && String(this.email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )) {
        document.querySelector("#emailadresse").classList.add("is-valid");
      } else {
        document.querySelector("#emailadresse").classList.add("is-invalid");
        valid = false;
      }

      if (this.telefon.length > 0 && String(this.telefon)
          .toLowerCase()
          .match(
              /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g
          )) {
        document.querySelector("#telefonnummer").classList.add("is-valid");
      } else {
        document.querySelector("#telefonnummer").classList.add("is-invalid");
        valid = false;
      }

      if (!isNaN(new Date(this.geburtsdatum)) && (new Date(this.geburtsdatum) < new Date())) {
        document.querySelector("#geburtstagkind").classList.add("is-valid");
      } else {
        document.querySelector("#geburtstagkind").classList.add("is-invalid");
        valid = false;
      }

      if (!isNaN(new Date(this.termin1)) && (new Date(this.termin1) > new Date())) {

        if(this.validateDateUrlaub(this.termin1, "#invalid-feedback-custom-termin1") && this.validateOrdinationsZeiten(this.termin1, "#invalid-feedback-custom-termin1")){
          document.querySelector("#untersuchungstermin").classList.add("is-valid");
        }else{
          document.querySelector("#untersuchungstermin").classList.add("is-invalid");
          valid = false;
        }

      } else {
        document.querySelector("#untersuchungstermin").classList.add("is-invalid");
        document.querySelector("#invalid-feedback-custom-termin1").innerHTML = "Das Datum kann nicht in der Vergangenheit liegen";

        valid = false;
      }

      if (!isNaN(new Date(this.termin2)) && (new Date(this.termin2) > new Date())) {

        if(this.validateDateUrlaub(this.termin2, "#invalid-feedback-custom-termin2") && this.validateOrdinationsZeiten(this.termin2, "#invalid-feedback-custom-termin2")){
          document.querySelector("#ersatztermin").classList.add("is-valid");
        }else{
          document.querySelector("#ersatztermin").classList.add("is-invalid");
          valid = false;
        }

      } else {
        document.querySelector("#ersatztermin").classList.add("is-invalid");
        document.querySelector("#invalid-feedback-custom-termin2").innerHTML = "Das Datum kann nicht in der Vergangenheit liegen";

        valid = false;
      }


      if (this.untersuchungen.impfung === true && (this.selectedImpfstoffe.length === 0)) {
        document.querySelector("#impfung").classList.add("is-invalid");
        document.querySelector("#invalid-custom-impfung").style.display="block";

        valid = false;
      }

      var selectedUntersuchungenCount = Object.entries(this.untersuchungen).filter(item => item[1] === true).length;

      if (selectedUntersuchungenCount === 0) {
        document.querySelector("#sonstiges").classList.add("is-invalid");
        document.querySelector("#invalid-custom-sonstiges").style.display="block";
        valid = false;
      }

      if ((this.untersuchungen.sonstiges === true && this.anmerkung.length === 0) || (this.untersuchungen.impfung === true && this.anmerkung.length === 0 && this.selectedImpfstoffe.includes(-1))) {
        document.querySelector("#comment").classList.add("is-invalid");
        valid = false;
      }

      return valid;
    },

    clearValidation() {
      document.querySelector("#vornamekind").classList.remove("is-valid");
      document.querySelector("#vornamekind").classList.remove("is-invalid");

      document.querySelector("#nachnamekind").classList.remove("is-valid");
      document.querySelector("#nachnamekind").classList.remove("is-invalid");

      document.querySelector("#geburtstagkind").classList.remove("is-valid");
      document.querySelector("#geburtstagkind").classList.remove("is-invalid");

      document.querySelector("#emailadresse").classList.remove("is-valid");
      document.querySelector("#emailadresse").classList.remove("is-invalid");

      document.querySelector("#telefonnummer").classList.remove("is-valid");
      document.querySelector("#telefonnummer").classList.remove("is-invalid");

      document.querySelector("#untersuchungstermin").classList.remove("is-valid");
      document.querySelector("#untersuchungstermin").classList.remove("is-invalid");

      document.querySelector("#ersatztermin").classList.remove("is-valid");
      document.querySelector("#ersatztermin").classList.remove("is-invalid");

      document.querySelector("#comment").classList.remove("is-valid");
      document.querySelector("#comment").classList.remove("is-invalid");

      document.querySelector("#impfung").classList.remove("is-invalid");
      document.querySelector("#sonstiges").classList.remove("is-invalid");


      document.querySelector("#invalid-custom-impfung").style.display="none";
      document.querySelector("#invalid-custom-sonstiges").style.display="none";

    }
  }
}
</script>

<style scoped>

</style>
