<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Kassenleistungen</h1>
        <div class="divider"><span class="bg-yellow radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>


    <div class="row card-stack">
      <div v-for="(item, index) in leistungen" v-bind:key="index" class="col-12 col-md-6 col-lg-4 col-xl-3">
        <div class="card bg-grey shadow text-center">
          <img :src="getImgUrl(item.image)" class="card-img-top">
          <div class="card-body">
            <p class="card-text"><span style="font-size:1.5rem">{{item.title}}</span></p>
            <router-link :to="item.url" class="button shadow bg-yellow medium">Mehr Informationen</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeistungKasseView',
  data: () => ({
    leistungen: [
      {title: "Untersuchung", url: "/leistungen/kasse/untersuchung/", image: "leistungen/untersuchung.jpg"},
      {title: "Blutabnahme", url: "/leistungen/kasse/blutabnahme/", image: "leistungen/blutabnahme.jpg"},
      {title: "Impfen", url: "/leistungen/kasse/impfen/", image: "leistungen/impfen.jpg"},
      {title: "Impfberatung", url: "/leistungen/kasse/impfberatung/", image: "leistungen/impfberatung.jpg"},
      {title: "Allergie", url: "/leistungen/kasse/allergie/", image: "leistungen/allergie.jpg"},
      {title: "Mutterkindpass", url: "/leistungen/kasse/mutterkindpass/", image: "leistungen/mutterkindpass.png"},
    ]
  }),
  methods:{
    getImgUrl: function(image) {
      return require('../../../../assets/' + image)
    }
  }
}
</script>

<style scoped>


</style>
