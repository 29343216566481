<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Impfberatung</h1>
        <div class="divider"><span class="bg-yellow radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>


    <div class="row">
      <div class="col-12 col-md-7">
        <p>Impfungen zählen wohl zu der wichtigsten Errungenschaft der Medizin. Als Kinder- und Jugendarzt
          möchte ich Sie bei diesem Thema gerne aufklären und beraten. Deshalb erhalten Sie sowohl im
          Rahmen der MKP-Untersuchung in der 4.-7.Lebenswoche als auch im 10.-14. Lebensmonat eine
          Aufklärung bezüglich der - dem Alter Ihres Kindes entsprechend - empfohlenen Impfungen. Bei
          jeder
          MKP-Untersuchung wird der Impfpass auf Vollständigkeit der verabreichten Impfungen
          kontrolliert.
        </p>


        <p>Ich sehe mich als Impffan, aber nicht als Impffanatiker. Das bedeutet, dass ich Ihrem Kind alle
          von Ihnen gewünschten Impfungen verabreiche. Sollten Sie sich aber gegen Impfen aussprechen, so
          wird diese Entscheidung akzeptiert, da ich davon ausgehe, dass Sie für Ihr Kind das Beste
          wollen.</p>
      </div>

      <div class="col-12 col-md-5">
        <div class="card bg-grey shadow">
          <div class="card-body">
            <h5 class="card-title bg-yellow">Informationen<i class="bi bi-info-circle-fill"></i></h5>

            <ul class="list">
              <FileRenderer :files="dokumente" color="yellow" location="Impfberatung"/>
            </ul>
          </div>
        </div>

        <div class="space-20"></div>
      </div>

    </div>
  </div>
</template>

<script>
import FileRenderer from "@/components/FileRenderer.vue";

import { useDocumentsStore } from '@/stores/documents'


export default {
  name: 'LeistungKasseImpfberatungView',
  components: {FileRenderer},

  computed: {
    dokumente() {
      return useDocumentsStore().getDocuments
    },
  }
}
</script>

<style scoped>


</style>
