<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Adminbereich</h1>
        <div class="divider"><span class="bg-red radius"></span></div>
        <div class="space-20"></div>
      </div>
    </div>


    <div class="row">
      <div class="col-12">

        <div class="card shadow">
          <div class="card-body">
            <div class="d-flex align-items-start">

              <nav class="bd-links flex-column " style="width: 200px!important; border-right: #e1e1e1 solid 1px; margin-right: 20px; padding-right: 20px">
                <ul class="bd-links-nav list-unstyled bd-links-nav-admin" role="tablist">

                  <li class="bd-links-group">
                    <strong class="bd-links-heading d-flex w-100 align-items-center fw-semibold">
                      <i class="bi green bi-calendar-fill"></i>&nbsp;&nbsp;Terminvergabe
                    </strong>
                    <ul class="list-unstyled fw-normal pb-2 small">
                      <li>
                        <a href="#" class="bd-links-link d-inline-block rounded active" id="v-termin-anfragen-tab" aria-current="page" aria-selected="true" data-bs-toggle="pill" data-bs-target="#v-termin-anfragen" type="button" role="tab" aria-controls="v-termin-anfragen">Anfragen</a>
                      </li>
                    </ul>
                  </li>

                  <li class="bd-links-group">
                    <strong class="bd-links-heading d-flex w-100 align-items-center fw-semibold">
                      <i class="bi violet bi-file-medical-fill"></i>&nbsp;&nbsp;Befunde
                    </strong>
                    <ul class="list-unstyled fw-normal pb-2 small">
                      <li>
                        <a href="#" class="bd-links-link d-inline-block rounded" id="v-befunde-tab" data-bs-toggle="pill" data-bs-target="#v-befunde" type="button" role="tab" aria-controls="v-befunde">Befundübermittlung</a>
                      </li>
                    </ul>
                  </li>


                  <li class="bd-links-group" v-if="isSuperAdmin">
                    <strong class="bd-links-heading d-flex w-100 align-items-center fw-semibold">
                      <i class="bi yellow bi-browser-chrome"></i>&nbsp;&nbsp;Website
                    </strong>
                    <ul class="list-unstyled fw-normal pb-2 small">
                      <li>
                        <a href="#" class="bd-links-link d-inline-block rounded" id="v-impfstoffe-tab" data-bs-toggle="pill" data-bs-target="#v-impfstoffe" type="button" role="tab" aria-controls="v-impfstoffe">Impfstoffe</a>
                      </li>
                      <li>
                        <a href="#" class="bd-links-link d-inline-block rounded" id="v-dokumente-tab" data-bs-toggle="pill" data-bs-target="#v-dokumente" type="button" role="tab" aria-controls="v-dokumente">Dokumente</a>
                      </li>
                      <hr style="margin: 5px 50px 5px 20px">
                      <li>
                        <a href="#" class="bd-links-link d-inline-block rounded" id="v-opentime-tab" data-bs-toggle="pill" data-bs-target="#v-opentime" type="button" role="tab" aria-controls="v-opentime">Öffnungszeiten</a>
                      </li>
                      <li>
                        <a href="#" class="bd-links-link d-inline-block rounded" id="v-urlaub-tab" data-bs-toggle="pill" data-bs-target="#v-urlaub" type="button" role="tab" aria-controls="v-urlaub">Urlaub</a>
                      </li>
                    </ul>
                  </li>

                  <li class="bd-links-group">
                    <strong class="bd-links-heading d-flex w-100 align-items-center fw-semibold">
                      <i class="bi blue bi-gear-fill"></i>&nbsp;&nbsp;System
                    </strong>
                    <ul class="list-unstyled fw-normal pb-2 small">
                      <li>
                        <a href="#" class="bd-links-link d-inline-block rounded" id="v-benutzer-tab" data-bs-toggle="pill" data-bs-target="#v-benutzer" type="button" role="tab" aria-controls="v-benutzer" v-if="isSuperAdmin">Benutzerverwaltung</a>
                      </li>
                      <li>
                        <a href="#" class="bd-links-link d-inline-block rounded" @click="logout()">Logout</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>

              <div class="tab-content d-flex flex-column" id="v-tabContent" style="width: 100%">
                <div class="tab-pane fade show active" id="v-termin-anfragen" role="tabpanel" aria-labelledby="v-termin-anfragen-tab" tabindex="0"><TerminvergabeAnfragen/></div>
                <div class="tab-pane fade" id="v-befunde" role="tabpanel" aria-labelledby="v-befunde-tab" tabindex="0"><BefundManager /></div>

                <div class="tab-pane fade" id="v-impfstoffe" role="tabpanel" aria-labelledby="v-impfstoffe-tab" tabindex="0" v-if="isSuperAdmin"><ImpfstoffManager /></div>
                <div class="tab-pane fade" id="v-dokumente" role="tabpanel" aria-labelledby="v-dokumente-tab" tabindex="0" v-if="isSuperAdmin"><FileManager/></div>
                <div class="tab-pane fade" id="v-opentime" role="tabpanel" aria-labelledby="v-opentime-tab" tabindex="0" v-if="isSuperAdmin"><OrdinationszeitenManager/></div>
                <div class="tab-pane fade" id="v-urlaub" role="tabpanel" aria-labelledby="v-urlaub-tab" tabindex="0" v-if="isSuperAdmin"><UrlaubManager/></div>

                <div class="tab-pane fade" id="v-benutzer" role="tabpanel" aria-labelledby="v-benutzer-tab" tabindex="0" v-if="isSuperAdmin"><UserManager/></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="space-20"></div>


    </div>
  </div>
</template>

<script>
import TerminvergabeAnfragen from "@/components/views/admin/TerminvergabeAnfragen.vue";
import UrlaubManager from "@/components/views/admin/UrlaubManager.vue";
import FileManager from "@/components/views/admin/FileManager.vue";
import ImpfstoffManager from "@/components/views/admin/ImpfstoffManager.vue";
import {useAdminStore} from "@/stores/admin";
import api from "@/axios";
import OrdinationszeitenManager from "@/components/views/admin/OrdinationszeitenManager.vue";
import UserManager from "@/components/views/admin/UserManager.vue";
import BefundManager from "@/components/views/admin/BefundManager.vue";

export default {
  name: 'AdminView',
  components: {
    BefundManager,
    UserManager,
    OrdinationszeitenManager, ImpfstoffManager, FileManager, UrlaubManager, TerminvergabeAnfragen},


  computed:{
    loggedIn(){
      return useAdminStore().isLoggedIn
    },
    isSuperAdmin(){
      return useAdminStore().isSuperAdmin
    }
  },
  async mounted() {
    var result = await useAdminStore().checkLogin();

    if(!result){
      useAdminStore().logout();
    }

    if (!useAdminStore().isLoggedIn) {
      this.$router.push("/admin/login");
    }
  },
  watch: {
    loggedIn: function(newVal) { // watch it
      if(!newVal){
        this.$router.push("/admin/login");
      }
    }
  },

  methods: {
    logout: function(){
      useAdminStore().logout()
    },

    test: async function () {
      var result = await api.get('/test', {});
      console.log(result)


    }
  }
}
</script>

<style scoped>


</style>
